import React, { useCallback, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  createSearchParams,
  URLSearchParamsInit,
  useNavigate,
} from 'react-router-dom';
import '../../css/base.css';
import '../../css/common.css';

import { useFadeAnimation } from '../../hooks/useFadeAnimation';
import useLocationChange from '../../hooks/useLocationChange';
import {
  QueryGetOrderIndexesWithCountsArgs,
  useGetOrderIndexesWithCountsQueryLazyQuery,
} from '../../types/api';
import { Municipality } from '../../types/misc';
import SearchBox from '../components/search/searchBox';
import SearchBreadList from '../components/search/searchBreadList';
import SearchDataNotExist from '../components/search/searchDataNotExist';
import SearchHelmet from '../components/search/searchHelmet';
import SearchQueryText from '../components/search/searchQueryText';
import SearchResult from '../components/search/searchResult';
import SearchSection from '../components/search/searchSection';
import { scrollToTop } from '../utils/scrollToTop';
const FIRST_PAGE = 1;

const Search = () => {
  const [selectedPrefecture, setPrefecture] = useState<string | null>(null);
  const [municipalities, setMunicipalities] = useState<Municipality[]>([]);
  const [selectedMunicipality, setMunicipality] = useState<string | null>(null);
  const [selectedOrder, setOrder] = useState<string | null>(null);
  const [selectedCertification, setCertification] = useState<string | null>(
    null,
  );

  const [queryPrefectureCode, setQueryPrefectureCode] = useState<string | null>(
    null,
  );
  const [queryMunicipalityCode, setQueryMunicipalityCode] = useState<
    string | null
  >(null);
  const [queryOrderType, setQueryOrderType] = useState<string | null>(null);
  const [queryCertification, setQueryCertification] = useState<string | null>(
    null,
  );
  const [queryPage, setQueryPage] = useState<number>(FIRST_PAGE);

  const [isSearched, setIsSearched] = useState<boolean>(false);

  const pageTopButtonRef = useFadeAnimation();

  const [fetchOrderIndexes, { loading, previousData, data }] =
    useGetOrderIndexesWithCountsQueryLazyQuery();

  const navigate = useNavigate();
  /**
   * 初回読み込み時・ブラウザgo/back時、URLからパラメータを取得
   */
  useLocationChange(async (location) => {
    const queryParams = new URLSearchParams(location.search);
    const prefectureCode = queryParams.get('prefectureCode');
    const municipalityCode = queryParams.get('municipalityCode');
    const orderType = queryParams.get('orderType');
    const certification =
      queryParams.get('certification') &&
      `certification_${queryParams.get('certification')}`;
    const page = !!queryParams.get('page')
      ? parseInt(queryParams.get('page') as string, 10)
      : 1;
    if (
      prefectureCode === null &&
      municipalityCode === null &&
      orderType === null &&
      certification === null &&
      !queryParams.get('page')
    ) {
      setIsSearched(false);
      return;
    }
    // フォーム側
    setPrefecture(prefectureCode);
    setMunicipality(municipalityCode);
    setOrder(orderType);
    setCertification(certification);

    // クエリ側
    setQueryPrefectureCode(prefectureCode);
    setQueryMunicipalityCode(municipalityCode);
    setQueryOrderType(orderType);
    setQueryCertification(certification);
    setQueryPage(page);

    await searchIndexes(
      prefectureCode,
      municipalityCode,
      orderType,
      certification,
      page,
    );
  });

  useEffect(() => {
    const fetchMunicipalities = async () => {
      if (selectedPrefecture) {

        const backendGraphqlUrl: string | undefined = process.env.REACT_APP_BACKEND_GRAPHQL_URL;
        if(!backendGraphqlUrl) return
        const updatedUrl = backendGraphqlUrl.replace(/graphql\/?$/, '') + 'search_area';

        const res = await fetch(
            `${updatedUrl}?prefecture_code=${selectedPrefecture}`
        );

        const response = await res.json();
        const municipalities = [...response.data];
        let cityName = '';
        const newMunicipalities = municipalities.map((municipality) => {
          if (
            municipality.name.endsWith('区') &&
            selectedPrefecture !== '13' // 東京特別区
          ) {
            municipality.name = `${cityName}${municipality.name}`;
          } else {
            cityName = municipality.name;
          }
          return municipality;
        });
        setMunicipalities(newMunicipalities);
        if (
          !(
            selectedMunicipality &&
            newMunicipalities
              .map((municipality) => municipality.id)
              .includes(selectedMunicipality)
          )
        ) {
          setMunicipality(null);
        }
      } else {
        setMunicipalities([]);
      }
    };
    fetchMunicipalities();
  }, [selectedPrefecture, selectedMunicipality]);

  useEffect(() => {
    if (isSearched) {
      document.body.classList.remove('search');
    } else {
      document.body.classList.add('search');
    }
  }, [isSearched]);

  const searchIndexes = async (
    prefectureCode: string | null,
    municipalityCode: string | null,
    orderType: string | null,
    certification: string | null,
    page: number,
  ) => {
    const variables: QueryGetOrderIndexesWithCountsArgs = {
      prefecture_code: prefectureCode || undefined,
      municipality_code: municipalityCode || undefined,
      order_type:
        orderType !== '' && orderType !== null
          ? parseInt(orderType, 10)
          : undefined,
      page,
    };
    if (certification) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      variables[certification] = true;
    }
    await fetchOrderIndexes({
      variables,
    });
    setIsSearched(true);
  };

  const pushState = (
    prefectureCode: string | null,
    municipalityCode: string | null,
    orderType: string | null,
    certification: string | null,
    page: number,
  ) => {
    const searchParams: URLSearchParamsInit = { page: `${page}` };
    if (prefectureCode) {
      searchParams.prefectureCode = prefectureCode;
    }
    if (municipalityCode) {
      searchParams.municipalityCode = municipalityCode;
    }
    if (orderType) {
      searchParams.orderType = orderType;
    }
    if (certification && certification.startsWith('certification_')) {
      const certificationContent = certification.split('certification_')[1];
      searchParams.certification = certificationContent;
    }
    navigate({
      pathname: '/search',
      search: createSearchParams(searchParams).toString(),
    });
  };

  const shiftPage = useCallback(
    async (page: number) => {
      const currentPage = new URLSearchParams(location.search).get('page');
      if (!currentPage || parseInt(currentPage) !== page) {
        await searchIndexes(
          queryPrefectureCode,
          queryMunicipalityCode,
          queryOrderType,
          queryCertification,
          page,
        );
        pushState(
          queryPrefectureCode,
          queryMunicipalityCode,
          queryOrderType,
          queryCertification,
          page,
        );
        setQueryPage(page);
      }
    },
    [
      queryPrefectureCode,
      queryMunicipalityCode,
      queryOrderType,
      queryCertification,
    ],
  );

  const clickSearchIndexes = useCallback(async () => {
    setQueryPrefectureCode(selectedPrefecture || null);
    setQueryMunicipalityCode(selectedMunicipality || null);
    setQueryOrderType(selectedOrder || null);
    setQueryCertification(selectedCertification || null);

    await searchIndexes(
      selectedPrefecture,
      selectedMunicipality,
      selectedOrder,
      selectedCertification,
      FIRST_PAGE,
    );
    pushState(
      selectedPrefecture,
      selectedMunicipality,
      selectedOrder,
      selectedCertification,
      FIRST_PAGE,
    );
    setQueryPage(FIRST_PAGE);
  }, [
    selectedPrefecture,
    selectedMunicipality,
    selectedOrder,
    selectedCertification,
  ]);

  const useData = loading ? previousData : data;

  return (
    <HelmetProvider>
      {/* TODO ResultのHelmetと統合する */}
      <SearchHelmet />
      <>
        <main className="main">
          {isSearched && <SearchBreadList municipalities={municipalities} />}
          <SearchSection isSearched={isSearched}>
            <>
              {isSearched ? (
                <SearchQueryText
                  prefectureCode={queryPrefectureCode}
                  municipalityCode={queryMunicipalityCode}
                  municipalities={municipalities}
                  orderType={queryOrderType}
                  certification={queryCertification}
                />
              ) : (
                <h1 className="page_headline search_page_headline">
                  求人をさがす
                </h1>
              )}
              {isSearched &&
                !loading &&
                useData?.getOrderIndexesWithCounts?.count === 0 && (
                  <SearchDataNotExist />
                )}
              <SearchBox
                isSearched={isSearched}
                municipalities={municipalities}
                selectedPrefecture={selectedPrefecture}
                selectedMunicipality={selectedMunicipality}
                selectedOrder={selectedOrder}
                selectedCertification={selectedCertification}
                setPrefecture={setPrefecture}
                setMunicipality={setMunicipality}
                setQueryMunicipalityCode={setQueryMunicipalityCode}
                setOrder={setOrder}
                setCertification={setCertification}
                clickSearchIndexes={clickSearchIndexes}
              />
            </>
          </SearchSection>

          {isSearched && useData && (
            <SearchResult
              data={useData}
              currentPage={queryPage}
              shiftPage={shiftPage}
              municipalities={municipalities}
            />
          )}
        </main>
        {isSearched && (
          <p
            id="page_top"
            className="page_top"
            ref={pageTopButtonRef}
            onClick={() => scrollToTop('smooth')}
          >
            <a className="pagetop_link"></a>
          </p>
        )}
      </>
    </HelmetProvider>
  );
};
export default Search;
