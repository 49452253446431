import React from 'react';

import { Municipality } from '../../../types/misc';
import {
  getCertification,
  getMunicipality,
  getOrderType,
} from '../../utils/constants/order';
import { PREFECTURE_OBJECT } from '../../utils/constants/prefectures';

interface SearchQueryTextProps {
  prefectureCode: string | null;
  municipalityCode: string | null;
  municipalities: Municipality[];
  orderType: string | null;
  certification: string | null;
}

const SearchQueryText = (props: SearchQueryTextProps): React.ReactElement => {
  const {
    prefectureCode,
    municipalityCode,
    municipalities,
    orderType,
    certification,
  } = props;
  const municipalityName = getMunicipality(municipalities, municipalityCode);
  const orderTypeName = getOrderType(orderType);
  const certificationName = getCertification(certification);
  return (
    <>
      <p className="page_headline search_page_headline">検索結果</p>
      <h1 className="result_headline">
        <span className="search_prefecture result_search_item">
          {prefectureCode && PREFECTURE_OBJECT[prefectureCode]}
        </span>
        <span className="search_city result_search_item">
          {municipalityName}
        </span>
        <br />
        <span className="search_job result_search_item">{orderTypeName}</span>
        <span className="search_certification result_search_item">
          {certificationName}
        </span>
        {!prefectureCode &&
          !municipalityName &&
          !orderTypeName &&
          !certificationName && (
            <span className="result_search_item">すべて</span>
          )}
        の求人
      </h1>
    </>
  );
};

export default SearchQueryText;
