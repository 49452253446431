import React from 'react';

const SearchDataNotExist = (): React.ReactElement => (
  <p className="nothing_message">
    条件に合う求人が見つかりませんでした。
    <span className="nothing_message_spinline">
      条件を変更し、もう一度お探しください。
    </span>
  </p>
);

export default SearchDataNotExist;
