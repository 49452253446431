import React from 'react';

interface SearchSectionProps {
  isSearched: boolean;
  children: React.ReactElement;
}

const SearchSection = (props: SearchSectionProps): React.ReactElement => {
  const { isSearched } = props;
  return (
    <section className={isSearched ? 'search_result--wrap' : 'search--wrap'}>
      {props.children}
    </section>
  );
};

export default SearchSection;
