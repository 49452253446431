import React, { useCallback, useEffect, useState } from 'react';

import { Municipality } from '../../../types/misc';
import { SEARCH_PARAMS_ORDER } from '../../utils/breadListType';
import {
  CERTIFICATIONS,
  ORDER_TYPES_OBJECT,
} from '../../utils/constants/order';
import { PREFECTURE_OBJECT } from '../../utils/constants/prefectures';

import CertificationLinks from './links/certificationLinks';
import MunicipalityLinks from './links/municipalityLinks';
import OrderTypeLinks from './links/orderTypeLinks';
import PrefectureLinks from './links/prefectureLinks';

interface Props {
  searchParams: string;
}

const RelationalSearchParamsLinks = (props: Props) => {
  const { searchParams } = props;
  const [municipalities, setMunicipalities] = useState<Municipality[]>([]);

  const queryParams = new URLSearchParams(searchParams);
  const prefectureCode = queryParams.get('prefectureCode');
  const municipalityCode = queryParams.get('municipalityCode');
  const orderType = queryParams.get('orderType');
  const certificationParam = queryParams.get('certification');
  const certification =
    certificationParam &&
    (/$certification_.*/.test(certificationParam)
      ? certificationParam
      : `certification_${certificationParam}`);

  const currentSearchConditionStrings: string[] = [];
  const urlParamList = ['/search?page=1'];
  // 職種
  urlParamList.splice(
    SEARCH_PARAMS_ORDER.orderType,
    0,
    !!orderType ? `orderType=${orderType}` : '',
  );
  currentSearchConditionStrings.splice(
    SEARCH_PARAMS_ORDER.orderType - 1,
    0,
    ORDER_TYPES_OBJECT.find((object) => object.code === orderType)?.name ?? '',
  );
  // こだわり条件
  urlParamList.splice(
    SEARCH_PARAMS_ORDER.certification,
    0,
    !!certification ? `certification=${certification.slice(14)}` : '',
  );
  currentSearchConditionStrings.splice(
    SEARCH_PARAMS_ORDER.certification - 1,
    0,
    CERTIFICATIONS.find((object) => object.certificationCode === certification)
      ?.certificationName ?? '',
  );
  // 都道府県
  urlParamList.splice(
    SEARCH_PARAMS_ORDER.prefectureCode,
    0,
    !!prefectureCode ? `prefectureCode=${prefectureCode}` : '',
  );
  currentSearchConditionStrings.splice(
    SEARCH_PARAMS_ORDER.prefectureCode - 1,
    0,
    !!prefectureCode ? PREFECTURE_OBJECT[prefectureCode] : '',
  );
  // 市区町村
  urlParamList.splice(
    SEARCH_PARAMS_ORDER.municipalityCode,
    0,
    !!municipalityCode ? `municipalityCode=${municipalityCode}` : '',
  );
  currentSearchConditionStrings.splice(
    SEARCH_PARAMS_ORDER.municipalityCode - 1,
    0,
    municipalities.find((municipality) => municipality.id === municipalityCode)
      ?.name ?? '',
  );

  useEffect(() => {
    const fetchMunicipalities = async () => {
      const backendGraphqlUrl: string | undefined = process.env.REACT_APP_BACKEND_GRAPHQL_URL;
      if(!backendGraphqlUrl) return
      const updatedUrl = backendGraphqlUrl.replace(/graphql\/?$/, '') + 'search_area';

      const res = await fetch(
          `${updatedUrl}?prefecture_code=${prefectureCode}`
      );

      const response = await res.json();
      const municipalities = [...response.data];
      let cityName = '';
      const newMunicipalities = municipalities.map((municipality) => {
        if (
          municipality.name.endsWith('区') &&
          prefectureCode !== '13' // 東京特別区
        ) {
          municipality.name = `${cityName}${municipality.name}`;
        } else {
          cityName = municipality.name;
        }
        return municipality;
      });
      setMunicipalities(newMunicipalities);
    };
    fetchMunicipalities();
  }, [prefectureCode]);

  const getParamsLink = useCallback(
    (param: string, order: number, replace?: boolean, replaceRange = 1) => {
      const copied = Array.from(urlParamList);
      copied.splice(order, replace ? replaceRange : 0, param);
      return copied.filter((param) => param !== '').join('&');
    },
    [urlParamList],
  );

  return (
    <div className="search_result_joblink--wrap">
      <MunicipalityLinks
        prefectureCode={prefectureCode}
        municipalityCode={municipalityCode}
        municipalities={municipalities}
        currentSearchConditionStrings={currentSearchConditionStrings}
        getParamsLink={getParamsLink}
      />
      <OrderTypeLinks
        orderType={orderType}
        currentSearchConditionStrings={currentSearchConditionStrings}
        getParamsLink={getParamsLink}
      />
      <CertificationLinks
        certification={certification}
        currentSearchConditionStrings={currentSearchConditionStrings}
        getParamsLink={getParamsLink}
      />
      {!!prefectureCode && (
        <PrefectureLinks
          prefectureCode={prefectureCode}
          municipalityCode={municipalityCode}
          currentSearchConditionStrings={currentSearchConditionStrings}
          getParamsLink={getParamsLink}
        />
      )}
    </div>
  );
};

export default RelationalSearchParamsLinks;
