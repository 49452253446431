import React from 'react';
import { Link } from 'react-router-dom';

import { BreadList, SEARCH_PARAMS_ORDER } from '../../utils/breadListType';
import { getCertification, getOrderType } from '../../utils/constants/order';

interface DetailBreadListProps {
  prefectureCode: string | null;
  prefecture: string | null;
  municipalityCode: string | null;
  municipality: string | null;
  orderType: number | null;
  queryParams: string;
  orderId: number;
}

const DetailBreadList = (props: DetailBreadListProps): React.ReactElement => {
  const {
    prefecture,
    prefectureCode,
    municipality,
    municipalityCode,
    orderType,
    queryParams,
    orderId,
  } = props;
  const breadList: BreadList[] = [];
  const urlParamList = ['/search?page=1'];

  if (queryParams) {
    const querySearchParams = new URLSearchParams(queryParams);
    const queryPrefectureCode = querySearchParams.get('prefectureCode');
    const queryMunicipalityCode = querySearchParams.get('municipalityCode');
    const queryOrderType = querySearchParams.get('orderType');
    const queryCertification =
      querySearchParams.get('certification') &&
      `certification_${querySearchParams.get('certification')}`;

    const orderTypeName = getOrderType(`${orderType}`);
    const certificationName = getCertification(queryCertification);

    if (queryOrderType !== null) {
      urlParamList.splice(
        SEARCH_PARAMS_ORDER.orderType,
        0,
        `orderType=${orderType}`,
      );
      breadList.push({
        url: urlParamList.join('&'),
        title: orderTypeName ?? '',
      });
    }
    if (queryCertification !== null) {
      urlParamList.splice(
        SEARCH_PARAMS_ORDER.certification,
        0,
        `certification=${querySearchParams.get('certification')}`,
      );
      breadList.push({
        url: urlParamList.join('&'),
        title: certificationName ?? '',
      });
    }
    if (queryPrefectureCode !== null) {
      urlParamList.splice(
        SEARCH_PARAMS_ORDER.prefectureCode,
        0,
        `prefectureCode=${prefectureCode}`,
      );
      breadList.push({
        url: urlParamList.join('&'),
        title: prefecture ?? '',
      });
    }
    if (queryMunicipalityCode !== null) {
      urlParamList.splice(
        SEARCH_PARAMS_ORDER.municipalityCode,
        0,
        `municipalityCode=${municipalityCode}`,
      );
      breadList.push({
        url: urlParamList.join('&'),
        title: municipality ?? '',
      });
    }
  }

  return (
    <ul className="bread_list--group">
      <li className="bread_list">
        <Link to="/search" className="bread_list_link">
          <img
            src={process.env.REACT_APP_SITE_PATH + 'images/home_icon.svg'}
            className="bread_list_home_icon"
            alt="家のアイコン"
          />
        </Link>
      </li>
      <>
        {breadList.length ? (
          breadList.map((item, index) => (
            <li key={index} className="bread_list">
              <Link to={item.url} className="bread_list_link">
                {item.title}
              </Link>
            </li>
          ))
        ) : (
          <li className="bread_list">
            <Link to={urlParamList.join('&')} className="bread_list_link">
              {'すべての求人'}
            </Link>
          </li>
        )}
      </>
      <li className="bread_list">{`求人id:${orderId}`}</li>
    </ul>
  );
};

export default DetailBreadList;
