import React from 'react';

import { StringKeyObject } from '../../utils/constants/prefectures';

interface SearchSelectListProps {
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  selectName: string;
  defaultOption: string;
  options: StringKeyObject[];
  value: string | undefined;
  valueKey: string;
  uniqueKey: string;
  text: string;
}

const SearchSelectList = (props: SearchSelectListProps): React.ReactElement => {
  const {
    onChange,
    selectName,
    defaultOption,
    options,
    value,
    valueKey,
    uniqueKey,
    text,
  } = props;
  return (
    <li className="select_list">
      <select
        name={selectName}
        className="search_select"
        onChange={onChange}
        value={value}
      >
        <option value="" className="search-option">
          {defaultOption}
        </option>
        {options.map((option) => {
          return (
            <option
              value={option[`${valueKey}`]}
              key={option[`${uniqueKey}`]}
              className="search-option"
            >
              {option[`${text}`]}
            </option>
          );
        })}
      </select>
    </li>
  );
};

export default SearchSelectList;
