import React from 'react';
import { Link } from 'react-router-dom';

import {
  getOrderTypeString,
  SEARCH_PARAMS_ORDER,
} from '../../../utils/breadListType';

interface Props {
  certification: string | null;
  currentSearchConditionStrings: string[];
  getParamsLink: (
    param: string,
    order: number,
    replace?: boolean,
    replaceRange?: number,
  ) => string;
}

const CertificationLinks = (props: Props): React.ReactElement => {
  const { certification, currentSearchConditionStrings, getParamsLink } = props;

  return (
    <>
      <input
        id="job_search_title_03"
        type="checkbox"
        className="toggle_button"
      />
      <label className="job_search_title" htmlFor="job_search_title_03">
        <img
          src={process.env.REACT_APP_SITE_PATH + 'images/check_icon.svg'}
          alt="チェックボックスのアイコン"
          className="job_search_title_icon"
        />
        {getOrderTypeString(currentSearchConditionStrings)
          ? `${getOrderTypeString(
              currentSearchConditionStrings,
            )}の求人を特徴で探す`
          : `求人を特徴で探す`}
      </label>
      <div className="job_search_list--wrap">
        <ul className="job_search_list--group">
          <li className="job_search_list">
            <Link
              to={getParamsLink(
                'certification=none_inexperienced',
                SEARCH_PARAMS_ORDER.certification,
                !!certification,
              )}
              className="text_link"
            >
              無資格未経験OK！
            </Link>
          </li>
          <li className="job_search_list">
            <Link
              to={getParamsLink(
                'certification=none_experienced',
                SEARCH_PARAMS_ORDER.certification,
                !!certification,
              )}
              className="text_link"
            >
              無資格でも経験があればOK！
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CertificationLinks;
