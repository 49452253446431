export interface BreadList {
  url: string;
  title: string;
}

export const SEARCH_PARAMS_ORDER = {
  page: 0,
  prefectureCode: 1,
  municipalityCode: 2,
  orderType: 3,
  certification: 4,
};

export const getOrderTypeString = (
  currentSearchConditionStrings: string[],
): string => {
  return currentSearchConditionStrings[SEARCH_PARAMS_ORDER.orderType - 1];
};
