import React from 'react';
import { Helmet } from 'react-helmet-async';

import { GetOrderIndexDetailByIdQuery } from '../../../types/api';

interface detailHelmetProps {
  orderId: number;
  data: GetOrderIndexDetailByIdQuery;
}

const generateRecruitmentJsonLd = (data: GetOrderIndexDetailByIdQuery) => {
  const {
    id,
    postal_code: postalCode,
    address_region: addressRegion,
    address_locality: addressLocality,
    street_address: streetAddress,
    task,
    facility_name,
    employment_status,
    min_hourly_salary,
    min_monthly_salary,
    order_type,
    created_at,
  } = data.getOrderIndexById.order_index;
  // 指定できる雇用形態
  // FULL_TIME：フルタイム（正社員）
  // PART_TIME：パートタイム
  // CONTRACTOR：契約社員
  // TEMPORARY：パートタイム（短期）
  // INTERN：インターン
  // VOLUNTEER：ボランティア
  // PER_DIEM：日雇い
  // OTHER：その他
  // キャリア様項目: 派遣, パート, 正社員, 紹介予定派遣(OTHER?)
  const employmentTypes = ['CONTRACTOR', 'PART_TIME', 'FULL_TIME', 'OTHER'];
  const orderTypes = [
    '初任者研修',
    '実務者研修',
    '介護福祉士',
    'ケアマネジャー',
    '正看護師',
    '准看護師',
    '保育士',
    '幼稚園教諭',
    'その他',
    '無資格未経験可',
    '無資格経験者可',
  ];

  return {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    datePosted: new Date(created_at).toISOString().substring(0, 10),
    title: orderTypes[order_type],
    description: `<p>${task}</p>`,
    hiringOrganization: facility_name
      ? {
          '@type': 'Organization',
          name: facility_name,
        }
      : 'confidential',
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressCountry: 'JP',
        addressRegion,
        addressLocality,
        postalCode,
        streetAddress,
      },
    },
    // 派遣, パート, 紹介予定派遣の場合
    ...(typeof employment_status === 'number' &&
      employment_status !== 3 && {
        baseSalary: {
          '@type': 'MonetaryAmount',
          currency: 'JPY',
          value: {
            '@type': 'QuantitativeValue',
            minValue: min_hourly_salary,
            unitText: 'HOUR',
          },
        },
      }),
    // 正社員の場合
    ...(typeof employment_status === 'number' &&
      employment_status === 3 && {
        baseSalary: {
          '@type': 'MonetaryAmount',
          currency: 'JPY',
          value: {
            '@type': 'QuantitativeValue',
            minValue: min_monthly_salary,
            unitText: 'MONTH',
          },
        },
      }),
    directApply: true,
    ...(typeof employment_status === 'number' && {
      employmentType: employmentTypes[employment_status],
    }),
    identifier: {
      '@type': 'PropertyValue',
      name: facility_name,
      value: id,
    },
  };
};

const generateSeoTitle = (data: GetOrderIndexDetailByIdQuery) => {
  const { recruitment_headline } = data.getOrderIndexById.order_index;
  return `${recruitment_headline}`;
};

const DetailHelmet = (props: detailHelmetProps): React.ReactElement => {
  const { orderId, data } = props;
  return (
    <Helmet>
      <head prefix="og: http://ogp.me/ns#" />
      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MSW6TRN');`}</script>
      <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
      <meta
        property="og:url"
        content="https://haken.careersmile.jp/recruit/index.html"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={`求人id:${orderId} | ${generateSeoTitle(data)}`}
      />
      <meta
        property="og:description"
        content="介護士・看護師の有資格者、無資格者、未経験者向けの全国の優良求人多数掲載中！看護助手、初任者研修、実務者研修、介護福祉士、准看護師、正看護師など資格や、勤務地からの検索も可能。毎日更新中！"
      />
      <meta
        property="og:image"
        content="https://haken.careersmile.jp/siteimage.png"
      />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <meta name="keywords" content="キャリア,介護,看護,保育,派遣" />
      <meta name="description" content="" />
      <title>{`求人id:${orderId} | ${generateSeoTitle(data)}`}</title>
      {/* TODO: favicon埋め込み */}
      <link rel="icon" href={process.env.PUBLIC_URL + '/favicon.ico'} />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <link
        href={process.env.PUBLIC_URL + '/css/recruit.css'}
        rel="stylesheet"
        type="text/css"
      />
      <noscript>
        {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MSW6TRN"
              height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>`}
      </noscript>
      <script type="application/ld+json">
        {JSON.stringify(generateRecruitmentJsonLd(data))}
      </script>
      {/* <!-- TETORI tag haken.careersmile.jp --> */}
      <script>
        {`(function (w, d, s, u) {
        // TAG VERSION 1.00
        if (w._wsq_init_flg) {
          return false;
        }
        w._wsq_init_flg = true;
        _wsq = w._wsq || (_wsq = []);
        _wsq.push(['init', u, 1827]);
        _wsq.push(['domain', 'haken.careersmile.jp']);
        var a = d.createElement(s); a.async = 1; a.charset='UTF-8'; a.src = 'https://cdn.' + u + '/share/js/tracking.js';
        var b = d.getElementsByTagName(s)[0]; b.parentNode.insertBefore(a, b);
      })(window, document, 'script', 'tetori.link');`}
      </script>
    </Helmet>
  );
};

export default DetailHelmet;
