import React from 'react';
import { Helmet } from 'react-helmet-async';

const TopHelmet = (): React.ReactElement => {
  return (
    <Helmet>
      <head prefix="og: http://ogp.me/ns#" />
      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
           })(window,document,'script','dataLayer','GTM-MSW6TRN');`}</script>
      <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
      <meta property="og:url" content="https://haken.careersmile.jp/" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="介護士 看護師 保育士のための求人サイト | キャリアスマイル派遣"
      />
      <meta
        property="og:description"
        content="介護士・看護師・保育士のための派遣求人情報『キャリアスマイル派遣』。全国の介護施設や福祉施設、病院、保育園など業界TOPクラスの高時給案件多数！日勤／夜勤、駅近、時短など、専任のコーディネーターがあなたの理想に合った職場をご紹介します！"
      />
      <meta
        property="og:image"
        content="https://haken.careersmile.jp/siteimage.png"
      />
      <meta
        name="thumbnail"
        content="https://haken.careersmile.jp/thumbnail.jpg"
      />
      <meta
        name="thumbnail"
        content="https://haken.careersmile.jp/thumbnail.jpg"
      />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />

      <meta name="keywords" content="キャリア,介護,看護,保育,派遣" />
      <meta
        name="description"
        content="介護士・看護師・保育士のための派遣求人情報『キャリアスマイル派遣』。全国の介護施設や福祉施設、病院、保育園など業界TOPクラスの高時給案件多数！日勤／夜勤、駅近、時短など、専任のコーディネーターがあなたの理想に合った職場をご紹介します！"
      />

      <title>
        介護士 看護師 保育士のための求人サイト | キャリアスマイル派遣
      </title>

      <link rel="icon" href="favicon.ico" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <link
        href={process.env.PUBLIC_URL + '/css/index.css'}
        rel="stylesheet"
        type="text/css"
      />
      <link rel="canonical" href="https://haken.careersmile.jp/" />

      <script>
        {`(function (w, d, s, u) {
            // TAG VERSION 1.00
            if (w._wsq_init_flg) {
                return false;
            }
            w._wsq_init_flg = true;
            _wsq = w._wsq || (_wsq = []);
            _wsq.push(['init', u, 1827]);
            _wsq.push(['domain', 'haken.careersmile.jp']);
            var a = d.createElement(s); a.async = 1; a.charset='UTF-8'; a.src = 'https://cdn.' + u + '/share/js/tracking.js';
            var b = d.getElementsByTagName(s)[0]; b.parentNode.insertBefore(a, b);
            })(window, document, 'script', 'tetori.link');`}
      </script>
      <noscript>
        {`<iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-MSW6TRN"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>`}
      </noscript>
    </Helmet>
  );
};

export default TopHelmet;
