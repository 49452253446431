import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { Municipality } from '../../types/misc';
import { BreadList, SEARCH_PARAMS_ORDER } from '../utils/breadListType';
import {
  getCertification,
  getMunicipality,
  getOrderType,
} from '../utils/constants/order';
import { PREFECTURE_OBJECT } from '../utils/constants/prefectures';

interface Props {
  children: ReactNode;
}

export interface BreadListContextType {
  breadList: BreadList[];
  updateBreadList: (
    prefectureCode: string | null,
    municipalityCode: string | null,
    municipalities: Municipality[],
    orderType: string | null,
    certification: string | null,
  ) => void;
}
export const BreadListContext = createContext<BreadListContextType>({
  breadList: [],
  updateBreadList: () => undefined,
});

export function BreadListContextProvider(props: Props): ReactElement {
  const { children } = props;
  const [breadList, setBreadList] = useState<BreadList[]>([]);

  const updateBreadList = useCallback(
    (
      prefectureCode: string | null,
      municipalityCode: string | null,
      municipalities: Municipality[],
      orderType: string | null,
      certification: string | null,
    ) => {
      const municipalityName = getMunicipality(
        municipalities,
        municipalityCode,
      );
      const orderTypeName = getOrderType(orderType);
      const certificationName = getCertification(certification);

      const newBreadlist: BreadList[] = [];
      const newUrlParamList = ['/search?page=1'];
      const breadTitleList = [];
      if (!!orderTypeName) {
        newUrlParamList.splice(
          SEARCH_PARAMS_ORDER.orderType,
          0,
          `orderType=${orderType}`,
        );
        breadTitleList.unshift(orderTypeName);
        newBreadlist.push({
          url: newUrlParamList.join('&'),
          title: orderTypeName,
        });
      }
      if (!!certificationName) {
        newUrlParamList.splice(
          SEARCH_PARAMS_ORDER.certification,
          0,
          `certification=${certification?.replace(/^certification_/, '')}`,
        );
        breadTitleList.unshift(certificationName);
        newBreadlist.push({
          url: newUrlParamList.join('&'),
          title: certificationName,
        });
      }
      if (!!prefectureCode) {
        newUrlParamList.splice(
          SEARCH_PARAMS_ORDER.prefectureCode,
          0,
          `prefectureCode=${prefectureCode}`,
        );
        breadTitleList.unshift(PREFECTURE_OBJECT[prefectureCode]);
        newBreadlist.push({
          url: newUrlParamList.join('&'),
          title: PREFECTURE_OBJECT[prefectureCode],
        });
      }
      if (!!municipalityName) {
        newUrlParamList.splice(
          SEARCH_PARAMS_ORDER.municipalityCode,
          0,
          `municipalityCode=${municipalityCode}`,
        );
        breadTitleList[0] = breadTitleList[0] + municipalityName;
        newBreadlist.push({
          url: newUrlParamList.join('&'),
          title: municipalityName,
        });
      }

      setBreadList(newBreadlist);
    },
    [],
  );

  const context = useMemo(
    () => ({
      breadList,
      updateBreadList,
    }),
    [breadList, updateBreadList],
  );
  return (
    <BreadListContext.Provider value={context}>
      {children}
    </BreadListContext.Provider>
  );
}
