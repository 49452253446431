import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import NewOrders from '../components/top/newOrders';
import TopHelmet from '../components/top/topHelmet';

import '../../css/base.css';
import '../../css/common.css';
import '../../css/slick.css';
import '../../css/slick-theme.css';

const Top = (): React.ReactElement => {
  return (
    <HelmetProvider>
      <TopHelmet />
      <main className="main toppage_main">
        <div className="mainvisual--wrap">
          <p className="mainvisual mainvisual_pc">
            <img
              src={process.env.REACT_APP_SITE_PATH + 'images/mainvisual.jpg'}
              alt=""
            />
          </p>
          <p className="mainvisual mainvisual_sp">
            <img
              src={process.env.REACT_APP_SITE_PATH + 'images/mainvisual_sp.jpg'}
              alt=""
            />
          </p>
          <ul className="mainvisual_text-wrap">
            <li className="mainvisual_text">
              <span className="text_pink">高時給</span>で日払い・週払いOK！
            </li>
            <li className="mainvisual_text">
              時短も長期も日勤も<span className="text_pink">求人豊富！</span>
            </li>
            <li className="mainvisual_text">
              常用型派遣も多数！
              <span className="text_pink">働き方が選べる</span>
            </li>
          </ul>
        </div>

        <div className="top_search_button--wrap">
          <p className="top_copy_search_button">
            <Link to="/search" className="top_copy_search_link">
              求人をさがす
            </Link>
          </p>
        </div>

        <div className="top_banner--wrap">
          <p className="top_banner">
            <a
              href="https://haken.careersmile.jp/friends/?utm_source=haken.careersmile.jp&utm_medium=banner&utm_campaign=top_banner"
              className="top_copy_friends_link"
            >
              <img
                src={
                  process.env.REACT_APP_SITE_PATH + 'images/banner_friend.webp'
                }
                alt="お友達紹介キャンペーン実施中！"
                width="420"
                height="105"
              />
            </a>
          </p>
          <p className="top_banner">
            <a
              href="https://college.careersmile.jp/?utm_source=haken.careersmile&utm_medium=banner&utm_campaign=%E6%B4%BE%E9%81%A3TOP"
              className="top_copy_friends_link"
            >
              <img
                src={
                  process.env.REACT_APP_SITE_PATH +
                  'images/haken420105.jpg'
                }
                alt="介護士資格の取得ならキャリアスマイルケアカレッジ"
                width="420"
                height="105"
              />
            </a>
          </p>
        </div>

        <section className="voice--wrap">
          <h2 className="voice_headline">派遣スタッフの声</h2>
          <div className="voice--inner slider--wrap">
            <div className="voice--box">
              <div className="voice_profile--wrap">
                <p className="voice_image_block">
                  <img
                    className="voice_image"
                    src={
                      process.env.REACT_APP_SITE_PATH + 'images/voice_01.jpg'
                    }
                    alt="20代 女性 看護師"
                  />
                </p>
                <ul className="voice_profile--group">
                  <li className="voice_profile">20代 / 女性</li>
                  <li className="voice_profile">看護師</li>
                </ul>
              </div>

              <h3 className="voice_title">
                とにかく早く働きたかったので、スピーディーな対応に感謝！
              </h3>
              <p className="voice_text">
                複数の派遣会社に登録しましたが、キャリアの対応がスピーディーで案件も豊富でしたので、即決でした。日払い制度もあり大変満足してます。
              </p>
            </div>

            <div className="voice--box">
              <div className="voice_profile--wrap">
                <p className="voice_image_block">
                  <img
                    className="voice_image"
                    src={
                      process.env.REACT_APP_SITE_PATH + 'images/voice_02.jpg'
                    }
                    alt="20代 女性 介護士"
                  />
                </p>
                <ul className="voice_profile--group">
                  <li className="voice_profile">20代 / 女性</li>
                  <li className="voice_profile">介護士</li>
                </ul>
              </div>

              <h3 className="voice_title">
                キャリアの資格取得支援制度を利用してキャリアアップに成功！
              </h3>
              <p className="voice_text">
                働きながら資格の勉強をすることにとても不安でしたが、無理なく自分のペースで学びながら資格を取得できたので、とても感謝してます。
              </p>
            </div>

            <div className="voice--box">
              <div className="voice_profile--wrap">
                <p className="voice_image_block">
                  <img
                    className="voice_image"
                    src={
                      process.env.REACT_APP_SITE_PATH + 'images/voice_03.jpg'
                    }
                    alt="30代 女性 看護士"
                  />
                </p>
                <ul className="voice_profile--group">
                  <li className="voice_profile">30代 / 女性</li>
                  <li className="voice_profile">看護士</li>
                </ul>
              </div>

              <h3 className="voice_title">
                希望条件にぴったり！おかげで家庭もプライベートも仕事も充実
              </h3>
              <p className="voice_text">
                家庭との両立でかなり細かな条件でしたが、とても良い条件の職場が見つかりました。友人紹介制度で勧めた友人も大満足しています。
              </p>
            </div>

            <div className="voice--box">
              <div className="voice_profile--wrap">
                <p className="voice_image_block">
                  <img
                    className="voice_image"
                    src={
                      process.env.REACT_APP_SITE_PATH + 'images/voice_04.jpg'
                    }
                    alt="30代 男性 介護士"
                  />
                </p>
                <ul className="voice_profile--group">
                  <li className="voice_profile">30代 / 男性</li>
                  <li className="voice_profile">介護士</li>
                </ul>
              </div>

              <h3 className="voice_title">
                今の職場の人に恵まれてるので、キャリアで選んでよかった
              </h3>
              <p className="voice_text">
                紹介予定派遣を利用しました。キャリアのスタッフの方がとても誠実で、なおかつ情報が豊富でしたので安心して決めました。
              </p>
            </div>

            <div className="voice--box">
              <div className="voice_profile--wrap">
                <p className="voice_image_block">
                  <img
                    className="voice_image"
                    src={
                      process.env.REACT_APP_SITE_PATH + 'images/voice_05.jpg'
                    }
                    alt="40代 女性 看護士"
                  />
                </p>
                <ul className="voice_profile--group">
                  <li className="voice_profile">40代 / 女性</li>
                  <li className="voice_profile">看護士</li>
                </ul>
              </div>

              <h3 className="voice_title">
                ブランクありでも高時給＆厚待遇！今の職場と出会えてよかった
              </h3>
              <p className="voice_text">
                数年のブランクがあり不安でしたが、コーディネーターの方がとても親身になってくださり、とても良い職場環境で働くことができました。
              </p>
            </div>
          </div>
        </section>

        <div className="register_button_wrap">
          <a href="/register/">
            <div className="register_button_box">
              <p className="register_button_image">
                <img
                  src={
                    process.env.REACT_APP_SITE_PATH +
                    'images/smairuca_button.svg'
                  }
                  alt="タイマーを持ったスマイルカ"
                />
              </p>
              <p className="register_button_text">
                <span className="register_button_text_subtitle">
                  カンタン<span className="text_pink">30秒</span>
                </span>
                <br />
                <span className="text_pink register_button_text_strong">
                  今すぐ無料相談
                </span>
                <br />
                ご希望の求人をご紹介します！
              </p>
            </div>
          </a>{' '}
        </div>

        <section className="contents--wrap">
          <section className="about--wrap" id="about">
            <p className="about_image_pc">
              <img
                src={process.env.REACT_APP_SITE_PATH + 'images/about_bg.jpg'}
                alt=""
              />
            </p>
            <p className="about_image_sp">
              <img
                src={
                  process.env.REACT_APP_SITE_PATH + 'images/about_image_sp.jpg'
                }
                alt=""
              />
            </p>
            <h2 className="about_headline_sp">キャリアスマイル派遣とは</h2>
            <div className="about_text--wrap">
              <h2 className="about_headline_pc">キャリアスマイル派遣とは</h2>
              <p>
                株式会社キャリアが運営する
                <span className="text_bold">
                  介護・看護・保育専門の派遣サービス
                </span>
                です。
                <br />
                全国に支店があり、各エリアに強いあなた専属のコーディネーターが、就業前のご相談・お仕事紹介から、就業後のサポートやキャリア相談なども
                <span className="text_bold">すべて無料で</span>実施いたします。
                <br />
                一緒に<span className="text_bold">”あなたらしい働き方”</span>
                を実現しましょう！
              </p>
            </div>
          </section>

          <section className="reason--wrap">
            <h2 className="reason_headline">
              キャリアスマイル派遣が
              <span className="text_inline">選ばれる理由</span>
            </h2>
            <div className="reason--inner">
              <div className="reason--box">
                <h3 className="reason_title">
                  <span className="text_blue">日払い・週払い</span>
                  <br />
                  OK！
                </h3>
                <p className="reason_text">
                  給料日を待たずに働いた分の給与の受け取りが可能です。24時間365日申請可能なので、急な出費でも安心です。
                </p>
              </div>

              <div className="reason--box">
                <p></p>
                <h3 className="reason_title">
                  <span className="text_blue">業界TOPクラス</span>の<br />
                  高時給！
                </h3>
                <p className="reason_text">
                  派遣先との信頼関係が強いので、あなたの経験に合わせて他社よりも高時給＆厚待遇な自信があります。
                </p>
              </div>

              <div className="reason--box">
                <h3 className="reason_title">
                  <span className="text_blue">求人件数豊富</span>で<br />
                  希望条件にマッチ！
                </h3>
                <p className="reason_text">
                  独占求人や非公開求人など、全国の求人件数が豊富にあるので、あなたの希望にあった職場をご紹介します。
                </p>
              </div>
            </div>
          </section>

          <section className="working_type--wrap" id="workstyle">
            <h2 className="contents_headline">選べる働きかた</h2>
            <div className="working_type--inner">
              <div className="working_type--box">
                <h3 className="working_type_title">一般派遣サービス</h3>
                <p>
                  <img
                    src={
                      process.env.REACT_APP_SITE_PATH +
                      'images/working_type01.svg'
                    }
                    alt="一般派遣サービスの図"
                  />
                </p>
                <div className="working_type_text--wrap">
                  <p className="working_type_text">
                    当社が雇用する派遣スタッフの方を、
                    <span className="text_pink">派遣先のご依頼に応じて</span>
                    派遣。<span className="text_pink">派遣先の指示のもと</span>
                    派遣スタッフの方にお仕事をしていただきます。
                  </p>
                  <p className="working_type_merit">
                    <span className="merit">メリット</span>
                    時給が高い。時短勤務や、週〇回など自分のライフスタイルに合わせた就業先が選べる。日払い・週払いが出来る。
                  </p>
                  <p className="working_type_merit">
                    <span className="demerit">デメリット</span>
                    契約期間が予め定められているので、その期間に合わせて働く必要がある。
                    <br />
                    ※派遣先とスタッフ様、双方の合意があれば契約を更新することができます
                  </p>
                </div>
              </div>

              <div className="working_type--box">
                <h3 className="working_type_title">紹介予定派遣サービス </h3>
                <p>
                  <img
                    src={
                      process.env.REACT_APP_SITE_PATH +
                      'images/working_type02.svg'
                    }
                    alt="紹介予定派遣サービスの図"
                  />
                </p>
                <div className="working_type_text--wrap">
                  <p className="working_type_text">
                    当社が雇用する派遣スタッフの方を、派遣先のご依頼に応じて派遣。
                    <span className="text_pink">
                      一定期間働いたのち双方合意の上、派遣先と直接雇用契約
                    </span>
                    を結んでいただきます。
                  </p>
                  <p className="working_type_merit">
                    <span className="merit">メリット</span>
                    いきなり正社員ではなく職場の雰囲気をじっくり見て正社員やパートを目指せ失敗リスクを最小限に抑えられる。{' '}
                  </p>
                  <p className="working_type_merit">
                    <span className="demerit">デメリット</span>
                    まれに不採用になる可能性もある。
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="support--wrap" id="support">
            <h2 className="support_headline">
              キャリアスマイル派遣の
              <span className="text_inline">安心サポート</span>
            </h2>
            <div className="support--inner">
              <div className="support--box">
                <h3 className="support_title">採用されやすい</h3>
                <p className="support_text">
                  就業先との信頼関係が強いので、あなたの専属のコーディネーターが勤務条件の交渉はもちろん、履歴書の添削や面接のアドバイスなどマンツーマンでサポートします。
                </p>
              </div>

              <div className="support--box">
                <h3 className="support_title">日払い、週払いもOK</h3>
                <p>
                  独自の前払いシステムで、スピーディーに日払い・週払いが可能です。スマホで簡単に手続きできるので、急な出費でも安心です。
                </p>
              </div>

              <div className="support--box">
                <h3 className="support_title">充実の福利厚生</h3>
                <p className="support_text">
                  交通費全額支給、社会保障完備だけではなく、資格取得制度、お仕事探しと同時にお子様の預け先の保育園も探せるなど他社にはない制度があります。
                </p>
                <p className="welfare_detail_button">
                  <a href="/welfare/" className="welfare_detail_link">
                    詳しくみる
                  </a>
                </p>
              </div>
            </div>
          </section>

          <div className="contents--inner">
            <section className="flow--wrap" id="flow">
              <h2 className="contents_headline">就業までのながれ</h2>
              <p className="flow_note">
                迷われている方もまずはお気軽にご相談ください。
                <br />
                <span className="line_pink">全て無料で</span>
                ご利用いただけます。
              </p>
              <div className="manual_video--inner">
                <video
                  width="100%"
                  height="auto"
                  src={process.env.REACT_APP_SITE_PATH + 'images/video.mp4'}
                  controls
                  poster={
                    process.env.REACT_APP_SITE_PATH + 'images/thumb_image.png'
                  }
                ></video>
              </div>
              <h3 className="flow_title">
                お仕事をはじめるまで
                <br />
                <span className="flow_title_number">3</span>ステップ
              </h3>
              <ol className="flow-group">
                <li className="flow_list">
                  <h4 className="flow_text">
                    <span className="flow_title_number">1</span>
                    無料相談・カンタン応募
                  </h4>
                </li>
                <li className="flow_list">
                  <h4 className="flow_text">
                    <span className="flow_title_number">2</span>
                    ヒアリング・お仕事紹介
                  </h4>
                </li>
                <li className="flow_list">
                  <h4 className="flow_text">
                    <span className="flow_title_number">3</span>職場見学・面談
                  </h4>
                </li>
                <li className="flow_list">
                  <h4 className="flow_text">お仕事スタート！</h4>
                </li>
              </ol>
            </section>

            <section className="faq--wrap" id="faq">
              <h2 className="faq_headline">よくあるご質問</h2>
              <div className="faq--inner">
                <input
                  id="faq_question_01"
                  type="checkbox"
                  className="toggle_button"
                />
                <label className="faq_question" htmlFor="faq_question_01">
                  無料で転職サポートが受けられますか？
                </label>
                <div className="faq_answer--wrap">
                  <p className="faq_answer">
                    お仕事探しや登録等には費用は一切かかりません。弊社の人材派遣･人材紹介サービスは厚生労働省の許可を受け、介護・医療・一般企業等の求人クライアントとお仕事を探している方の仲介をしております。
                  </p>
                </div>

                <input
                  id="faq_question_02"
                  type="checkbox"
                  className="toggle_button"
                />
                <label className="faq_question" htmlFor="faq_question_02">
                  無資格でも登録できますか？
                </label>
                <div className="faq_answer--wrap">
                  <p className="faq_answer">
                    登録できます。無資格の方や未経験の方もたくさんご活躍されています。また、資格取得支援制度もございます。
                  </p>
                </div>

                <input
                  id="faq_question_03"
                  type="checkbox"
                  className="toggle_button"
                />
                <label className="faq_question" htmlFor="faq_question_03">
                  交通費は支給されますか？
                </label>
                <div className="faq_answer--wrap">
                  <p className="faq_answer">はい、支給はございます。</p>
                </div>

                <input
                  id="faq_question_04"
                  type="checkbox"
                  className="toggle_button"
                />
                <label className="faq_question" htmlFor="faq_question_04">
                  今すぐの転職は考えていないのですが、登録できますか？
                </label>
                <div className="faq_answer--wrap">
                  <p className="faq_answer">
                    登録可能です。条件や時期などのご相談だけでもお受けしております！
                  </p>
                </div>

                <input
                  id="faq_question_05"
                  type="checkbox"
                  className="toggle_button"
                />
                <label className="faq_question" htmlFor="faq_question_05">
                  年齢やブランクが心配…
                </label>
                <div className="faq_answer--wrap">
                  <p className="faq_answer">
                    弊社は年齢制限を設けておらず、ブランクがある方もご案内しておりますので、お気軽にご登録ください！
                  </p>
                </div>

                <input
                  id="faq_question_06"
                  type="checkbox"
                  className="toggle_button"
                />
                <label className="faq_question" htmlFor="faq_question_06">
                  Wワークは可能ですか？
                </label>
                <div className="faq_answer--wrap">
                  <p className="faq_answer">
                    可能です。週１～２回などのスキマ時間を活用したい方にも最適です。
                  </p>
                </div>

                <input
                  id="faq_question_07"
                  type="checkbox"
                  className="toggle_button"
                />
                <label className="faq_question" htmlFor="faq_question_07">
                  社会保険に加入できますか？
                </label>
                <div className="faq_answer--wrap">
                  <p className="faq_answer">
                    お仕事の就業条件が社会保険の加入資格を満たす場合、加入手続きを実施しております。
                  </p>
                </div>

                <input
                  id="faq_question_08"
                  type="checkbox"
                  className="toggle_button"
                />
                <label className="faq_question" htmlFor="faq_question_08">
                  日払い・週払い可能ですか？
                </label>
                <div className="faq_answer--wrap">
                  <p className="faq_answer">
                    可能です。登録時にご希望をお伝え下さい。
                  </p>
                </div>
              </div>
            </section>

            <section className="new_job--wrap">
              <h2 className="new_job_headline">新着のお仕事</h2>
              <NewOrders />
            </section>

            <section className="job_search--wrap">
              <h2 className="job_search_headline">お仕事を探す</h2>
              <input
                id="job_search_title_01"
                type="checkbox"
                className="toggle_button"
              />
              <label className="job_search_title" htmlFor="job_search_title_01">
                <img
                  src={process.env.REACT_APP_SITE_PATH + 'images/area_icon.svg'}
                  alt="スポットのアイコン"
                  className="job_search_title_icon"
                />
                エリアから探す
              </label>
              <div className="job_search_list--wrap">
                <div className="job_attension_area--wrap">
                  <h3 className="job_attension_area_title">注目エリア</h3>
                  <ul className="job_attension_list--group">
                    <li className="job_search_list">
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          '/search?page=1&prefectureCode=27&municipalityCode=27100'
                        }
                        className="text_link"
                      >
                        大阪府大阪市
                      </Link>
                    </li>
                    <li className="job_search_list">
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          '/search?page=1&prefectureCode=14&municipalityCode=14100'
                        }
                        className="text_link"
                      >
                        神奈川県横浜市
                      </Link>
                    </li>
                    <li className="job_search_list">
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          '/search?page=1&prefectureCode=01&municipalityCode=01100'
                        }
                        className="text_link"
                      >
                        北海道札幌市
                      </Link>
                    </li>
                    <li className="job_search_list">
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          '/search?page=1&prefectureCode=40&municipalityCode=40130'
                        }
                        className="text_link"
                      >
                        福岡県福岡市
                      </Link>
                    </li>
                    <li className="job_search_list">
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          '/search?page=1&prefectureCode=11&municipalityCode=11100'
                        }
                        className="text_link"
                      >
                        埼玉県さいたま市
                      </Link>
                    </li>
                    <li className="job_search_list">
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          '/search?page=1&prefectureCode=13&municipalityCode=13112'
                        }
                        className="text_link"
                      >
                        東京都世田谷区
                      </Link>
                    </li>
                    <li className="job_search_list">
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          '/search?page=1&prefectureCode=23&municipalityCode=23100'
                        }
                        className="text_link"
                      >
                        愛知県名古屋市
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="job_search_area--wrap">
                  <div className="job_search--box">
                    <input
                      id="job_search_areaname_01"
                      type="checkbox"
                      className="toggle_areabutton"
                    />
                    <label
                      className="job_search_areaname"
                      htmlFor="job_search_areaname_01"
                    >
                      北海道・東北
                    </label>
                    <ul className="job_search_list--areagroup">
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=01'
                          }
                          className="text_link"
                        >
                          北海道
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=02'
                          }
                          className="text_link"
                        >
                          青森
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=03'
                          }
                          className="text_link"
                        >
                          岩手
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=04'
                          }
                          className="text_link"
                        >
                          宮城
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=05'
                          }
                          className="text_link"
                        >
                          秋田
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=06'
                          }
                          className="text_link"
                        >
                          山形
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=07'
                          }
                          className="text_link"
                        >
                          福島
                        </Link>
                      </li>
                    </ul>
                    <input
                      id="job_search_areaname_02"
                      type="checkbox"
                      className="toggle_areabutton"
                    />
                    <label
                      className="job_search_areaname"
                      htmlFor="job_search_areaname_02"
                    >
                      関東
                    </label>
                    <ul className="job_search_list--areagroup">
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=08'
                          }
                          className="text_link"
                        >
                          茨城
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=09'
                          }
                          className="text_link"
                        >
                          栃木
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=10'
                          }
                          className="text_link"
                        >
                          群馬
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=11'
                          }
                          className="text_link"
                        >
                          埼玉
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=12'
                          }
                          className="text_link"
                        >
                          千葉
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=13'
                          }
                          className="text_link"
                        >
                          東京
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=14'
                          }
                          className="text_link"
                        >
                          神奈川
                        </Link>
                      </li>
                    </ul>
                    <input
                      id="job_search_areaname_03"
                      type="checkbox"
                      className="toggle_areabutton"
                    />
                    <label
                      className="job_search_areaname"
                      htmlFor="job_search_areaname_03"
                    >
                      甲信越・北陸
                    </label>
                    <ul className="job_search_list--areagroup">
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=15'
                          }
                          className="text_link"
                        >
                          新潟
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=16'
                          }
                          className="text_link"
                        >
                          富山
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=17'
                          }
                          className="text_link"
                        >
                          石川
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=18'
                          }
                          className="text_link"
                        >
                          福井
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=19'
                          }
                          className="text_link"
                        >
                          山梨
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=20'
                          }
                          className="text_link"
                        >
                          長野
                        </Link>
                      </li>
                    </ul>
                    <input
                      id="job_search_areaname_04"
                      type="checkbox"
                      className="toggle_areabutton"
                    />
                    <label
                      className="job_search_areaname"
                      htmlFor="job_search_areaname_04"
                    >
                      東海
                    </label>
                    <ul className="job_search_list--areagroup">
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=21'
                          }
                          className="text_link"
                        >
                          岐阜
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=22'
                          }
                          className="text_link"
                        >
                          静岡
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=23'
                          }
                          className="text_link"
                        >
                          愛知
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=24'
                          }
                          className="text_link"
                        >
                          三重
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="job_search--box">
                    <input
                      id="job_search_areaname_05"
                      type="checkbox"
                      className="toggle_areabutton"
                    />
                    <label
                      className="job_search_areaname"
                      htmlFor="job_search_areaname_05"
                    >
                      関西
                    </label>
                    <ul className="job_search_list--areagroup">
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=25'
                          }
                          className="text_link"
                        >
                          滋賀
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=26'
                          }
                          className="text_link"
                        >
                          京都
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=27'
                          }
                          className="text_link"
                        >
                          大阪
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=28'
                          }
                          className="text_link"
                        >
                          兵庫
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=29'
                          }
                          className="text_link"
                        >
                          奈良
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=30'
                          }
                          className="text_link"
                        >
                          和歌山
                        </Link>
                      </li>
                    </ul>
                    <input
                      id="job_search_areaname_06"
                      type="checkbox"
                      className="toggle_areabutton"
                    />
                    <label
                      className="job_search_areaname"
                      htmlFor="job_search_areaname_06"
                    >
                      中国・四国
                    </label>
                    <ul className="job_search_list--areagroup">
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=31'
                          }
                          className="text_link"
                        >
                          鳥取
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=32'
                          }
                          className="text_link"
                        >
                          島根
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=33'
                          }
                          className="text_link"
                        >
                          岡山
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=34'
                          }
                          className="text_link"
                        >
                          広島
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=35'
                          }
                          className="text_link"
                        >
                          山口
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=36'
                          }
                          className="text_link"
                        >
                          徳島
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=37'
                          }
                          className="text_link"
                        >
                          香川
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=38'
                          }
                          className="text_link"
                        >
                          愛媛
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=39'
                          }
                          className="text_link"
                        >
                          高知
                        </Link>
                      </li>
                    </ul>
                    <input
                      id="job_search_areaname_07"
                      type="checkbox"
                      className="toggle_areabutton"
                    />
                    <label
                      className="job_search_areaname"
                      htmlFor="job_search_areaname_07"
                    >
                      九州・沖縄
                    </label>
                    <ul className="job_search_list--areagroup">
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=40'
                          }
                          className="text_link"
                        >
                          福岡
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=41'
                          }
                          className="text_link"
                        >
                          佐賀
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=42'
                          }
                          className="text_link"
                        >
                          長崎
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=43'
                          }
                          className="text_link"
                        >
                          熊本
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=44'
                          }
                          className="text_link"
                        >
                          大分
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=45'
                          }
                          className="text_link"
                        >
                          宮崎
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=46'
                          }
                          className="text_link"
                        >
                          鹿児島
                        </Link>
                      </li>
                      <li className="job_search_list">
                        <Link
                          to={
                            process.env.PUBLIC_URL +
                            '/search?page=1&prefectureCode=47'
                          }
                          className="text_link"
                        >
                          沖縄
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <input
                id="job_search_title_02"
                type="checkbox"
                className="toggle_button"
              />
              <label className="job_search_title" htmlFor="job_search_title_02">
                <img
                  src={process.env.REACT_APP_SITE_PATH + 'images/work_icon.svg'}
                  alt="ビルのアイコン"
                  className="job_search_title_icon"
                />
                職種から探す
              </label>
              <div className="job_search_list--wrap">
                <ul className="job_search_list--group">
                  <li className="job_search_list">
                    <Link
                      to={process.env.PUBLIC_URL + '/search?page=1&orderType=0'}
                      className="text_link"
                    >
                      介護
                    </Link>
                  </li>
                  <li className="job_search_list">
                    <Link
                      to={process.env.PUBLIC_URL + '/search?page=1&orderType=1'}
                      className="text_link"
                    >
                      看護
                    </Link>
                  </li>
                  <li className="job_search_list">
                    <Link
                      to={process.env.PUBLIC_URL + '/search?page=1&orderType=3'}
                      className="text_link"
                    >
                      保育
                    </Link>
                  </li>
                  <li className="job_search_list">
                    <Link
                      to={process.env.PUBLIC_URL + '/search?page=1&orderType=2'}
                      className="text_link"
                    >
                      その他
                    </Link>
                  </li>
                </ul>
              </div>

              <input
                id="job_search_title_03"
                type="checkbox"
                className="toggle_button"
              />
              <label className="job_search_title" htmlFor="job_search_title_03">
                <img
                  src={
                    process.env.REACT_APP_SITE_PATH + 'images/check_icon.svg'
                  }
                  alt="チェックボックスのアイコン"
                  className="job_search_title_icon"
                />
                こだわり条件から探す
              </label>
              <div className="job_search_list--wrap">
                <ul className="job_search_list--group">
                  <li className="job_search_list">
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        '/search?page=1&certification=none_inexperienced'
                      }
                      className="text_link"
                    >
                      無資格未経験OK！
                    </Link>
                  </li>
                  <li className="job_search_list">
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        '/search?page=1&certification=none_experienced'
                      }
                      className="text_link"
                    >
                      無資格でも経験があればOK！
                    </Link>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </section>
      </main>
      <p id="page_top">
        <a href="/#" className="pagetop_link"></a>
      </p>
      <div id="action_button" className="fotter_fix_button--wrap hide-btn">
        <ul className="fotter_fix_button--group">
          <li className="fotter_fix_contact_button fotter_fix_button_menu">
            <a href="/register/" className="fotter_fix_button_link">
              無料相談・お仕事紹介は<span className="text_inline">こちら</span>
            </a>
          </li>
          <li className="fotter_fix_phone_button fotter_fix_button_menu">
            <a href="tel:05017409296" className="fotter_fix_button_link">
              <img
                src={process.env.REACT_APP_SITE_PATH + 'images/phone_icon.svg'}
                className="fotter_fix_button_icon"
                alt="電話のアイコン"
              />
            </a>
          </li>
        </ul>
      </div>
    </HelmetProvider>
  );
};

export default Top;
