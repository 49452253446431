import React, { useCallback } from 'react';

import { Municipality } from '../../../types/misc';
import {
  CERTIFICATIONS,
  ORDER_TYPES_OBJECT,
} from '../../utils/constants/order';
import { PREFECTURES_AND_CODES } from '../../utils/constants/prefectures';

import SearchSelectList from './searchSelectList';

interface SearchBoxProps {
  isSearched: boolean;
  municipalities: Municipality[];
  selectedPrefecture: string | null;
  selectedMunicipality: string | null;
  selectedOrder: string | null;
  selectedCertification: string | null;
  setPrefecture: (value: string) => void;
  setMunicipality: (value: string | null) => void;
  setQueryMunicipalityCode: (value: string | null) => void;
  setOrder: (value: string) => void;
  setCertification: (value: string) => void;
  clickSearchIndexes: () => void;
}

const SearchBox = (props: SearchBoxProps) => {
  const {
    isSearched,
    municipalities,
    selectedPrefecture,
    selectedMunicipality,
    selectedOrder,
    selectedCertification,
    setPrefecture,
    setMunicipality,
    setQueryMunicipalityCode,
    setOrder,
    setCertification,
    clickSearchIndexes,
  } = props;

  const onChangePrefecture = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setPrefecture(e.target.value);
      if (e.target.value == '') {
        setMunicipality(null);
        setQueryMunicipalityCode(null);
      }
    },
    [],
  );
  return (
    <div className="search--box">
      <div>
        <ul className="select_list--group">
          <SearchSelectList
            onChange={onChangePrefecture}
            selectName="select_prefecture"
            defaultOption="都道府県"
            options={PREFECTURES_AND_CODES}
            value={selectedPrefecture ?? ''}
            valueKey={'code'}
            uniqueKey={'code'}
            text={'prefectureName'}
          />
          <SearchSelectList
            onChange={(e) => setMunicipality(e.target.value)}
            selectName="select_city"
            defaultOption="市区町村"
            options={municipalities}
            value={selectedMunicipality ?? ''}
            valueKey={'id'}
            uniqueKey={'id'}
            text={'name'}
          />
          <SearchSelectList
            onChange={(e) => setOrder(e.target.value)}
            selectName="select_job"
            defaultOption="職種"
            options={ORDER_TYPES_OBJECT}
            value={selectedOrder ?? ''}
            valueKey={'code'}
            uniqueKey={'code'}
            text={'name'}
          />
          <SearchSelectList
            onChange={(e) => setCertification(e.target.value)}
            selectName="select_certification"
            defaultOption="資格"
            options={CERTIFICATIONS}
            value={selectedCertification ?? ''}
            valueKey={'certificationCode'}
            uniqueKey={'certificationCode'}
            text={'certificationName'}
          />
        </ul>
        <p className="search_button_text">
          <button
            className={
              isSearched ? 're_search_button search_button' : 'search_button'
            }
            type="button"
            onClick={clickSearchIndexes}
          >
            {isSearched ? '再検索する' : '検索する'}
          </button>
        </p>
      </div>
    </div>
  );
};

export default SearchBox;
