import React, { ReactElement } from 'react';

interface ApplicationButtonProps {
  orderId: number;
  officeEmail: string;
  title: string;
  url: string;
}

const ApplicationButton = (props: ApplicationButtonProps): ReactElement => {
  const { orderId, officeEmail, title, url } = props;
  return (
    <form
      action={process.env.REACT_APP_SITE_PATH + 'recruit/entry.php'}
      method="post"
    >
      <input type="hidden" name="id" value={orderId} />
      <input type="hidden" name="office_email" value={officeEmail} />
      <input type="hidden" name="title" value={title} />
      <input type="hidden" name="url" value={url} />
      <p className="register_button_text">
        <button className="register_button">応募する</button>
      </p>
    </form>
  );
};

export default ApplicationButton;
