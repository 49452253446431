import React from 'react';
import { Link } from 'react-router-dom';

import {
  CERTIFICATIONS,
  EMPLOYMENT_TYPES,
  EMPLOYMENT_TYPES_NUMBER,
} from '../../../utils/constants/order';

type OrderIndex = {
  __typename?: 'OrderIndex';
  id: any;
  order_id: any;
  certification_helper_novice: boolean;
  certification_helper_expert: boolean;
  certification_care_worker: boolean;
  certification_care_manager: boolean;
  certification_nurse: boolean;
  certification_associate_nurse: boolean;
  certification_child_minder: boolean;
  certification_kindergarten_teacher: boolean;
  certification_other: boolean;
  certification_none_inexperienced: boolean;
  certification_none_experienced: boolean;
  recruitment_headline: string;
  employment_status: number;
  facility_name?: string | null;
  location: string;
  access: string;
  working_hours: string;
  min_hourly_salary?: number | null;
  min_daily_salary?: number | null;
  min_monthly_salary?: number | null;
  min_annual_salary?: number | null;
};

interface SearchResultCardProps {
  order: OrderIndex;
}

const SearchResultCard = (props: SearchResultCardProps): React.ReactElement => {
  const { order } = props;
  return (
    <div className="job_list--box" key={order.id}>
      <h2 className="job_list_headline">{order.recruitment_headline}</h2>
      <div className="job_list--group">
        <table>
          <tbody>
            <tr>
              <th className="job_list_title">
                <img
                  src={
                    process.env.REACT_APP_SITE_PATH +
                    'images/certification_icon.svg'
                  }
                  alt="勲章のアイコン"
                  className="job_list_icon"
                  height={18}
                  width={18}
                />
                必要な資格
              </th>
              <td className="job_list_text">
                {CERTIFICATIONS.map((certification) => {
                  return order[
                    certification.certificationCode as keyof typeof order
                  ]
                    ? certification.certificationName
                    : '';
                })
                  .filter((item) => item)
                  .join('、')}
              </td>
            </tr>
            <tr>
              <th className="job_list_title">
                <img
                  src={
                    process.env.REACT_APP_SITE_PATH + 'images/status_icon.svg'
                  }
                  alt="会社員のアイコン"
                  className="job_list_icon"
                  height={18}
                  width={18}
                />
                雇用形態
              </th>
              <td className="job_list_text">
                {EMPLOYMENT_TYPES[order.employment_status]}
              </td>
            </tr>
            {order.employment_status !==
              EMPLOYMENT_TYPES_NUMBER.INTRODUCE_PROPER && (
              <tr>
                <th className="job_list_title">
                  <img
                    src={
                      process.env.REACT_APP_SITE_PATH + 'images/pay_icon.svg'
                    }
                    alt="お財布のアイコン"
                    className="job_list_icon"
                    height={18}
                    width={18}
                  />
                  時給
                </th>
                <td className="job_list_text">
                  {Number(order.min_hourly_salary).toLocaleString()}円～
                </td>
              </tr>
            )}
            {order.working_hours.includes('夜勤') && (
              <tr>
                <th className="job_list_title">
                  <img
                    src={
                      process.env.REACT_APP_SITE_PATH + 'images/pay_icon.svg'
                    }
                    alt="お財布のアイコン"
                    className="job_list_icon"
                    height={18}
                    width={18}
                  />
                  日給
                </th>
                <td className="job_list_text">
                  {Number(order.min_daily_salary).toLocaleString()}円～
                </td>
              </tr>
            )}
            {order.employment_status ===
              EMPLOYMENT_TYPES_NUMBER.INTRODUCE_PROPER && (
              <tr>
                <th className="job_list_title">
                  <img
                    src={
                      process.env.REACT_APP_SITE_PATH + 'images/pay_icon.svg'
                    }
                    alt="お財布のアイコン"
                    className="job_list_icon"
                    height={18}
                    width={18}
                  />
                  月給
                </th>
                <td className="job_list_text">
                  {Number(order.min_monthly_salary).toLocaleString()}
                  円～
                </td>
              </tr>
            )}
            {order.employment_status ===
              EMPLOYMENT_TYPES_NUMBER.INTRODUCE_PROPER && (
              <tr>
                <th className="job_list_title">
                  <img
                    src={
                      process.env.REACT_APP_SITE_PATH + 'images/pay_icon.svg'
                    }
                    alt="お財布のアイコン"
                    className="job_list_icon"
                    height={18}
                    width={18}
                  />
                  年収
                </th>
                <td className="job_list_text">
                  {Number(order.min_annual_salary).toLocaleString()}円～
                </td>
              </tr>
            )}

            <tr>
              <th className="job_list_title">
                <img
                  src={
                    process.env.REACT_APP_SITE_PATH + 'images/clock_icon.svg'
                  }
                  alt="時計のアイコン"
                  className="job_list_icon"
                  height={18}
                  width={18}
                />
                就業時間
              </th>
              <td className="job_list_text">
                {order.working_hours
                  .split(/　|\\n/)
                  .filter((text) => text)
                  .join('、')}
              </td>
            </tr>
          </tbody>
        </table>
        <dl className="job_place_item">
          <dt className="job_place_name">{order.facility_name}</dt>
          <dd className="job_place_address">{order.location}</dd>
          <dd className="job_place_access">{order.access}</dd>
        </dl>
        <p className="detail_button_text">
          {' '}
          <Link
            to={`/detail/${order.id}`}
            state={{
              queryParams: location.search,
            }}
            className="detail_button"
            onClick={() => window.scrollTo(0, 0)}
          >
            詳しくみる
          </Link>{' '}
        </p>
      </div>
    </div>
  );
};

export default SearchResultCard;
