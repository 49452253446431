import React from 'react';
import { Link } from 'react-router-dom';

import {
  SEARCH_PARAMS_ORDER,
} from '../../../utils/breadListType';
import { ORDER_TYPES_OBJECT } from '../../../utils/constants/order';

interface Props {
  orderType: string | null;
  currentSearchConditionStrings: string[];
  getParamsLink: (
    param: string,
    order: number,
    replace?: boolean,
    replaceRange?: number,
  ) => string;
}

const OrderTypeLinks = (props: Props): React.ReactElement => {
  const { orderType, getParamsLink } = props;

  return (
    <>
      <input
        id="job_search_title_02"
        type="checkbox"
        className="toggle_button"
      />
      <label className="job_search_title" htmlFor="job_search_title_02">
        <img
          src={process.env.REACT_APP_SITE_PATH + 'images/work_icon.svg'}
          alt="ビルのアイコン"
          className="job_search_title_icon"
          height={18}
          width={18}
        />
        {`求人を職種から探す`}
      </label>
      <div className="job_search_list--wrap">
        <ul className="job_search_list--group">
          {ORDER_TYPES_OBJECT.map((orderObject) => (
            <li key={orderObject.code} className="job_search_list">
              <Link
                to={getParamsLink(
                  `orderType=${orderObject.code}`,
                  SEARCH_PARAMS_ORDER.orderType,
                  !!orderType,
                )}
                className="text_link"
              >
                {orderObject.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default OrderTypeLinks;
