import React from 'react';
import { Link } from 'react-router-dom';

import {
  getOrderTypeString,
  SEARCH_PARAMS_ORDER,
} from '../../../utils/breadListType';
import {
  PREFECTURE_OBJECT,
  REGION_MAP,
  REGION_OBJECT,
} from '../../../utils/constants/prefectures';

interface Props {
  prefectureCode: string;
  municipalityCode: string | null;
  currentSearchConditionStrings: string[];
  getParamsLink: (
    param: string,
    order: number,
    replace?: boolean,
    replaceRange?: number,
  ) => string;
}

const PrefectureLinks = (props: Props): React.ReactElement => {
  const {
    prefectureCode,
    municipalityCode,
    currentSearchConditionStrings,
    getParamsLink,
  } = props;

  const region = prefectureCode && REGION_OBJECT[prefectureCode];
  const sameRegionPrefectures = region && REGION_MAP[region];

  return (
    <>
      <input
        id="job_search_title_04"
        type="checkbox"
        className="toggle_button"
      />
      <label className="job_search_title" htmlFor="job_search_title_04">
        <img
          src={process.env.REACT_APP_SITE_PATH + 'images/area_icon.svg'}
          alt="スポットのアイコン"
          className="job_search_title_icon"
        />
        {`他の都道府県から${
          getOrderTypeString(currentSearchConditionStrings)
            ? getOrderTypeString(currentSearchConditionStrings) + 'の'
            : ''
        }求人を探す`}
      </label>
      <div className="job_search_list--wrap">
        <ul className="job_search_list--group">
          {sameRegionPrefectures &&
            sameRegionPrefectures.map((prefecture) => (
              <li key={prefecture} className="job_search_list">
                <Link
                  to={getParamsLink(
                    `prefectureCode=${prefecture}`,
                    SEARCH_PARAMS_ORDER.prefectureCode,
                    true,
                    !!municipalityCode ? 2 : 1,
                  )}
                  className="text_link"
                >
                  {PREFECTURE_OBJECT[prefecture]}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default PrefectureLinks;
