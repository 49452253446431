import React from 'react';

interface NewlineTextProps {
  text: string;
}

const NewlineText = (props: NewlineTextProps) => {
  const { text } = props;
  return (
    <>
      {text.split(/(\n)/).map((item, index) => (
        <React.Fragment key={index}>
          {item.match(/\n/) ? <br /> : item}
        </React.Fragment>
      ))}
    </>
  );
};

export default NewlineText;
