import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ApplicationFooter from './views/components/common/applicationFooter';
import ApplicationHeader from './views/components/common/applicationHeader';
import ApplicationNavigation from './views/components/common/applicationNavigation';
import { BreadListContextProvider } from './views/contexts/BreadListContext';
import Top from './views/pages/Top';
import Detail from './views/pages/detail';
import Search from './views/pages/search';

const App = () => (
  <div className="wrapper">
    <ApplicationHeader />
    <ApplicationNavigation />
    <BreadListContextProvider>
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/search" element={<Search />} />
        <Route path="/detail/:id" element={<Detail />} />
      </Routes>
    </BreadListContextProvider>
    <ApplicationFooter />
  </div>
);

export default App;
