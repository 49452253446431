import React from 'react';
import { Link } from 'react-router-dom';

const ApplicationNavigation = (): React.ReactElement => {
  return (
    <nav className="header_nav_pc">
      <ul className="header_menu_pc">
        <li className="header_menu_list_pc">
          <Link to="/search" className="header_menu_link_search_pc">
            <img
              src={
                process.env.REACT_APP_SITE_PATH + 'images/search_icon_blue.svg'
              }
              alt=""
              className="header_menu_icon_search_pc"
              height={15}
              width={15}
            />
            求人をさがす
          </Link>
        </li>
        <li className="header_menu_list_pc">
          <a
            href={process.env.REACT_APP_SITE_PATH}
            className="header_menu_link_pc"
          >
            トップ
          </a>
        </li>
        <li className="header_menu_list_pc">
          <a
            href={process.env.REACT_APP_SITE_PATH + '#about'}
            className="header_menu_link_pc"
          >
            キャリアスマイル派遣とは
          </a>
        </li>
        <li className="header_menu_list_pc">
          <a
            href={process.env.REACT_APP_SITE_PATH + 'company/'}
            className="header_menu_link_pc"
          >
            採用担当者様へ
          </a>
        </li>
        <li className="header_menu_list_pc">
          <a
            href={process.env.REACT_APP_SITE_PATH + 'welfare/'}
            className="header_menu_link_pc"
          >
            福利厚生
          </a>
        </li>
        <li className="header_menu_list_pc">
          <a
            href={process.env.REACT_APP_SITE_PATH + 'system/'}
            className="header_menu_link_pc"
          >
            資格取得支援制度
          </a>
        </li>
        <li className="header_menu_list_pc header_menu_list_register_pc">
          <a
            href={process.env.REACT_APP_SITE_PATH + 'register/'}
            className="header_menu_link_register_pc"
          >
            今すぐ無料相談 ▶
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default ApplicationNavigation;
