import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Timestamp: any;
  Void: any;
};

export type ApplicationFriendsPostInput = {
  email: Scalars['String'];
  friend_email: Scalars['String'];
  friend_id: Scalars['String'];
  friend_name: Scalars['String'];
  job_category: Scalars['String'];
  municipality: Scalars['String'];
  name: Scalars['String'];
  office_emails: Scalars['String'];
  prefecture: Scalars['String'];
  tel: Scalars['String'];
};

export type ApplicationRecruitPostInput = {
  birth_date: Scalars['String'];
  certifications: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  first_name_kana?: InputMaybe<Scalars['String']>;
  job_category: Scalars['String'];
  last_name: Scalars['String'];
  last_name_kana?: InputMaybe<Scalars['String']>;
  municipality: Scalars['String'];
  office_email: Scalars['String'];
  order_id: Scalars['Int'];
  postal_code: Scalars['String'];
  prefecture: Scalars['String'];
  request: Scalars['String'];
  support_requested?: InputMaybe<Scalars['Boolean']>;
  tel: Scalars['String'];
};

export type ApplicationRegisterPostInput = {
  birth_date: Scalars['String'];
  certifications: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  first_name_kana?: InputMaybe<Scalars['String']>;
  job_category: Scalars['String'];
  last_name: Scalars['String'];
  last_name_kana?: InputMaybe<Scalars['String']>;
  municipality: Scalars['String'];
  office_emails: Scalars['String'];
  postal_code: Scalars['String'];
  prefecture: Scalars['String'];
  request: Scalars['String'];
  support_requested: Scalars['Boolean'];
  tel: Scalars['String'];
};

export type GetOrderIndexByIdResponse = {
  __typename?: 'GetOrderIndexByIdResponse';
  order_index: OrderIndexDetail;
};

export type GetOrderIndexesResponse = {
  __typename?: 'GetOrderIndexesResponse';
  count: Scalars['Int'];
  order_indexes: Array<OrderIndex>;
};

export type Mutation = {
  __typename?: 'Mutation';
  postApplicationFriends?: Maybe<Scalars['Void']>;
  postApplicationRecruit?: Maybe<Scalars['Void']>;
  postApplicationRegister?: Maybe<Scalars['Void']>;
};

export type MutationPostApplicationFriendsArgs = {
  data: ApplicationFriendsPostInput;
};

export type MutationPostApplicationRecruitArgs = {
  data: ApplicationRecruitPostInput;
};

export type MutationPostApplicationRegisterArgs = {
  data: ApplicationRegisterPostInput;
};

export type OrderIndex = {
  __typename?: 'OrderIndex';
  access: Scalars['String'];
  appeal_points: Scalars['String'];
  certification_associate_nurse: Scalars['Boolean'];
  certification_care_manager: Scalars['Boolean'];
  certification_care_worker: Scalars['Boolean'];
  certification_child_minder: Scalars['Boolean'];
  certification_helper_expert: Scalars['Boolean'];
  certification_helper_novice: Scalars['Boolean'];
  certification_kindergarten_teacher: Scalars['Boolean'];
  certification_none_experienced: Scalars['Boolean'];
  certification_none_inexperienced: Scalars['Boolean'];
  certification_nurse: Scalars['Boolean'];
  certification_other: Scalars['Boolean'];
  employment_status: Scalars['Float'];
  facility_name?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  location: Scalars['String'];
  min_annual_salary?: Maybe<Scalars['Int']>;
  min_daily_salary?: Maybe<Scalars['Int']>;
  min_hourly_salary?: Maybe<Scalars['Int']>;
  min_monthly_salary?: Maybe<Scalars['Int']>;
  municipality_code: Scalars['String'];
  order_id: Scalars['BigInt'];
  order_type: Scalars['Int'];
  prefecture_code: Scalars['String'];
  recruitment_headline: Scalars['String'];
  working_hours: Scalars['String'];
};

export type OrderIndexDetail = {
  __typename?: 'OrderIndexDetail';
  access: Scalars['String'];
  address_locality?: Maybe<Scalars['String']>;
  address_region?: Maybe<Scalars['String']>;
  annual_income_breakdown: Scalars['String'];
  appeal_points: Scalars['String'];
  certification_associate_nurse: Scalars['Boolean'];
  certification_care_manager: Scalars['Boolean'];
  certification_care_worker: Scalars['Boolean'];
  certification_child_minder: Scalars['Boolean'];
  certification_helper_expert: Scalars['Boolean'];
  certification_helper_novice: Scalars['Boolean'];
  certification_kindergarten_teacher: Scalars['Boolean'];
  certification_none_experienced: Scalars['Boolean'];
  certification_none_inexperienced: Scalars['Boolean'];
  certification_nurse: Scalars['Boolean'];
  certification_other: Scalars['Boolean'];
  created_at?: Maybe<Scalars['Timestamp']>;
  days_until_join?: Maybe<Scalars['Int']>;
  employment_status: Scalars['Float'];
  extra_certification: Scalars['String'];
  facility_name?: Maybe<Scalars['String']>;
  facility_type?: Maybe<Scalars['String']>;
  holiday: Scalars['String'];
  id: Scalars['BigInt'];
  location: Scalars['String'];
  min_annual_salary?: Maybe<Scalars['Int']>;
  min_daily_salary?: Maybe<Scalars['Int']>;
  min_hourly_salary?: Maybe<Scalars['Int']>;
  min_monthly_salary?: Maybe<Scalars['Int']>;
  municipality_code: Scalars['String'];
  office_email?: Maybe<Scalars['String']>;
  order_id: Scalars['BigInt'];
  order_type: Scalars['Int'];
  other_requirements: Scalars['String'];
  postal_code?: Maybe<Scalars['String']>;
  prefecture_code: Scalars['String'];
  recruitment_headline: Scalars['String'];
  remarks: Scalars['String'];
  required_documents: Scalars['String'];
  required_skills: Scalars['String'];
  salary_free_description: Scalars['String'];
  street_address?: Maybe<Scalars['String']>;
  task: Scalars['String'];
  travel_expenses_free_description: Scalars['String'];
  treatment_for_direct_employment: Scalars['String'];
  treatment_for_dispatch: Scalars['String'];
  updated_at?: Maybe<Scalars['Timestamp']>;
  working_hours: Scalars['String'];
  working_hours_free_description: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getOrderIndexById: GetOrderIndexByIdResponse;
  getOrderIndexesWithCounts: GetOrderIndexesResponse;
};

export type QueryGetOrderIndexByIdArgs = {
  id: Scalars['BigInt'];
};

export type QueryGetOrderIndexesWithCountsArgs = {
  certification_associate_nurse?: InputMaybe<Scalars['Boolean']>;
  certification_care_manager?: InputMaybe<Scalars['Boolean']>;
  certification_care_worker?: InputMaybe<Scalars['Boolean']>;
  certification_child_minder?: InputMaybe<Scalars['Boolean']>;
  certification_helper_expert?: InputMaybe<Scalars['Boolean']>;
  certification_helper_novice?: InputMaybe<Scalars['Boolean']>;
  certification_kindergarten_teacher?: InputMaybe<Scalars['Boolean']>;
  certification_none_experienced?: InputMaybe<Scalars['Boolean']>;
  certification_none_inexperienced?: InputMaybe<Scalars['Boolean']>;
  certification_nurse?: InputMaybe<Scalars['Boolean']>;
  certification_other?: InputMaybe<Scalars['Boolean']>;
  municipality_code?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  prefecture_code?: InputMaybe<Scalars['String']>;
};

export type GetOrderIndexDetailByIdQueryVariables = Exact<{
  id: Scalars['BigInt'];
}>;

export type GetOrderIndexDetailByIdQuery = {
  __typename?: 'Query';
  getOrderIndexById: {
    __typename?: 'GetOrderIndexByIdResponse';
    order_index: {
      __typename?: 'OrderIndexDetail';
      id: any;
      order_id: any;
      office_email?: string | null;
      postal_code?: string | null;
      address_region?: string | null;
      address_locality?: string | null;
      street_address?: string | null;
      certification_helper_novice: boolean;
      certification_helper_expert: boolean;
      certification_care_worker: boolean;
      certification_care_manager: boolean;
      certification_nurse: boolean;
      certification_associate_nurse: boolean;
      certification_child_minder: boolean;
      certification_kindergarten_teacher: boolean;
      certification_other: boolean;
      certification_none_inexperienced: boolean;
      certification_none_experienced: boolean;
      prefecture_code: string;
      municipality_code: string;
      order_type: number;
      recruitment_headline: string;
      appeal_points: string;
      extra_certification: string;
      facility_name?: string | null;
      facility_type?: string | null;
      min_hourly_salary?: number | null;
      min_daily_salary?: number | null;
      min_monthly_salary?: number | null;
      min_annual_salary?: number | null;
      salary_free_description: string;
      annual_income_breakdown: string;
      location: string;
      access: string;
      travel_expenses_free_description: string;
      task: string;
      employment_status: number;
      working_hours: string;
      working_hours_free_description: string;
      treatment_for_dispatch: string;
      treatment_for_direct_employment: string;
      holiday: string;
      required_skills: string;
      other_requirements: string;
      required_documents: string;
      remarks: string;
      days_until_join?: number | null;
      created_at?: any | null;
      updated_at?: any | null;
    };
  };
};

export type GetOrderIndexesWithCountsQueryQueryVariables = Exact<{
  prefecture_code?: InputMaybe<Scalars['String']>;
  municipality_code?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Scalars['Int']>;
  certification_helper_novice?: InputMaybe<Scalars['Boolean']>;
  certification_helper_expert?: InputMaybe<Scalars['Boolean']>;
  certification_care_worker?: InputMaybe<Scalars['Boolean']>;
  certification_care_manager?: InputMaybe<Scalars['Boolean']>;
  certification_nurse?: InputMaybe<Scalars['Boolean']>;
  certification_associate_nurse?: InputMaybe<Scalars['Boolean']>;
  certification_child_minder?: InputMaybe<Scalars['Boolean']>;
  certification_kindergarten_teacher?: InputMaybe<Scalars['Boolean']>;
  certification_other?: InputMaybe<Scalars['Boolean']>;
  certification_none_inexperienced?: InputMaybe<Scalars['Boolean']>;
  certification_none_experienced?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetOrderIndexesWithCountsQueryQuery = {
  __typename?: 'Query';
  getOrderIndexesWithCounts: {
    __typename?: 'GetOrderIndexesResponse';
    count: number;
    order_indexes: Array<{
      __typename?: 'OrderIndex';
      id: any;
      order_id: any;
      certification_helper_novice: boolean;
      certification_helper_expert: boolean;
      certification_care_worker: boolean;
      certification_care_manager: boolean;
      certification_nurse: boolean;
      certification_associate_nurse: boolean;
      certification_child_minder: boolean;
      certification_kindergarten_teacher: boolean;
      certification_other: boolean;
      certification_none_inexperienced: boolean;
      certification_none_experienced: boolean;
      recruitment_headline: string;
      employment_status: number;
      facility_name?: string | null;
      location: string;
      access: string;
      working_hours: string;
      min_hourly_salary?: number | null;
      min_daily_salary?: number | null;
      min_monthly_salary?: number | null;
      min_annual_salary?: number | null;
      order_type: number;
    }>;
  };
};

export const GetOrderIndexDetailByIdDocument = gql`
  query getOrderIndexDetailById($id: BigInt!) {
    getOrderIndexById(id: $id) {
      order_index {
        id
        order_id
        office_email
        postal_code
        address_region
        address_locality
        street_address
        certification_helper_novice
        certification_helper_expert
        certification_care_worker
        certification_care_manager
        certification_nurse
        certification_associate_nurse
        certification_child_minder
        certification_kindergarten_teacher
        certification_other
        certification_none_inexperienced
        certification_none_experienced
        prefecture_code
        municipality_code
        order_type
        recruitment_headline
        appeal_points
        extra_certification
        facility_name
        facility_type
        min_hourly_salary
        min_daily_salary
        min_monthly_salary
        min_annual_salary
        salary_free_description
        annual_income_breakdown
        salary_free_description
        location
        access
        travel_expenses_free_description
        task
        employment_status
        working_hours
        working_hours_free_description
        treatment_for_dispatch
        treatment_for_direct_employment
        holiday
        required_skills
        other_requirements
        required_documents
        remarks
        days_until_join
        created_at
        updated_at
      }
    }
  }
`;

/**
 * __useGetOrderIndexDetailByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderIndexDetailByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderIndexDetailByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderIndexDetailByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderIndexDetailByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderIndexDetailByIdQuery,
    GetOrderIndexDetailByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrderIndexDetailByIdQuery,
    GetOrderIndexDetailByIdQueryVariables
  >(GetOrderIndexDetailByIdDocument, options);
}
export function useGetOrderIndexDetailByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderIndexDetailByIdQuery,
    GetOrderIndexDetailByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrderIndexDetailByIdQuery,
    GetOrderIndexDetailByIdQueryVariables
  >(GetOrderIndexDetailByIdDocument, options);
}
export type GetOrderIndexDetailByIdQueryHookResult = ReturnType<
  typeof useGetOrderIndexDetailByIdQuery
>;
export type GetOrderIndexDetailByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrderIndexDetailByIdLazyQuery
>;
export type GetOrderIndexDetailByIdQueryResult = Apollo.QueryResult<
  GetOrderIndexDetailByIdQuery,
  GetOrderIndexDetailByIdQueryVariables
>;
export const GetOrderIndexesWithCountsQueryDocument = gql`
  query getOrderIndexesWithCountsQuery(
    $prefecture_code: String
    $municipality_code: String
    $order_type: Int
    $certification_helper_novice: Boolean
    $certification_helper_expert: Boolean
    $certification_care_worker: Boolean
    $certification_care_manager: Boolean
    $certification_nurse: Boolean
    $certification_associate_nurse: Boolean
    $certification_child_minder: Boolean
    $certification_kindergarten_teacher: Boolean
    $certification_other: Boolean
    $certification_none_inexperienced: Boolean
    $certification_none_experienced: Boolean
    $page: Int
    $limit: Int
  ) {
    getOrderIndexesWithCounts(
      prefecture_code: $prefecture_code
      municipality_code: $municipality_code
      order_type: $order_type
      certification_helper_novice: $certification_helper_novice
      certification_helper_expert: $certification_helper_expert
      certification_care_worker: $certification_care_worker
      certification_care_manager: $certification_care_manager
      certification_nurse: $certification_nurse
      certification_associate_nurse: $certification_associate_nurse
      certification_child_minder: $certification_child_minder
      certification_kindergarten_teacher: $certification_kindergarten_teacher
      certification_other: $certification_other
      certification_none_inexperienced: $certification_none_inexperienced
      certification_none_experienced: $certification_none_experienced
      page: $page
      limit: $limit
    ) {
      order_indexes {
        id
        order_id
        certification_helper_novice
        certification_helper_expert
        certification_care_worker
        certification_care_manager
        certification_nurse
        certification_associate_nurse
        certification_child_minder
        certification_kindergarten_teacher
        certification_other
        certification_none_inexperienced
        certification_none_experienced
        recruitment_headline
        employment_status
        facility_name
        location
        access
        working_hours
        min_hourly_salary
        min_daily_salary
        min_monthly_salary
        min_annual_salary
        order_type
      }
      count
    }
  }
`;

/**
 * __useGetOrderIndexesWithCountsQueryQuery__
 *
 * To run a query within a React component, call `useGetOrderIndexesWithCountsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderIndexesWithCountsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderIndexesWithCountsQueryQuery({
 *   variables: {
 *      prefecture_code: // value for 'prefecture_code'
 *      municipality_code: // value for 'municipality_code'
 *      order_type: // value for 'order_type'
 *      certification_helper_novice: // value for 'certification_helper_novice'
 *      certification_helper_expert: // value for 'certification_helper_expert'
 *      certification_care_worker: // value for 'certification_care_worker'
 *      certification_care_manager: // value for 'certification_care_manager'
 *      certification_nurse: // value for 'certification_nurse'
 *      certification_associate_nurse: // value for 'certification_associate_nurse'
 *      certification_child_minder: // value for 'certification_child_minder'
 *      certification_kindergarten_teacher: // value for 'certification_kindergarten_teacher'
 *      certification_other: // value for 'certification_other'
 *      certification_none_inexperienced: // value for 'certification_none_inexperienced'
 *      certification_none_experienced: // value for 'certification_none_experienced'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetOrderIndexesWithCountsQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrderIndexesWithCountsQueryQuery,
    GetOrderIndexesWithCountsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrderIndexesWithCountsQueryQuery,
    GetOrderIndexesWithCountsQueryQueryVariables
  >(GetOrderIndexesWithCountsQueryDocument, options);
}
export function useGetOrderIndexesWithCountsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderIndexesWithCountsQueryQuery,
    GetOrderIndexesWithCountsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrderIndexesWithCountsQueryQuery,
    GetOrderIndexesWithCountsQueryQueryVariables
  >(GetOrderIndexesWithCountsQueryDocument, options);
}
export type GetOrderIndexesWithCountsQueryQueryHookResult = ReturnType<
  typeof useGetOrderIndexesWithCountsQueryQuery
>;
export type GetOrderIndexesWithCountsQueryLazyQueryHookResult = ReturnType<
  typeof useGetOrderIndexesWithCountsQueryLazyQuery
>;
export type GetOrderIndexesWithCountsQueryQueryResult = Apollo.QueryResult<
  GetOrderIndexesWithCountsQueryQuery,
  GetOrderIndexesWithCountsQueryQueryVariables
>;
