import React from 'react';
import { Link } from 'react-router-dom';

import { Municipality } from '../../../../types/misc';
import {
  getOrderTypeString,
  SEARCH_PARAMS_ORDER,
} from '../../../utils/breadListType';
import {
  PREFECTURE_OBJECT,
  REGIONS,
  REGION_MAP,
} from '../../../utils/constants/prefectures';

interface Props {
  prefectureCode: string | null;
  municipalityCode: string | null;
  municipalities: Municipality[];
  currentSearchConditionStrings: string[];
  getParamsLink: (
    param: string,
    order: number,
    replace?: boolean,
    replaceRange?: number,
  ) => string;
}

const MunicipalityLinks = (props: Props): React.ReactElement => {
  const {
    prefectureCode,
    municipalityCode,
    municipalities,
    currentSearchConditionStrings,
    getParamsLink,
  } = props;

  return (
    <>
      <input
        id="job_search_title_01"
        type="checkbox"
        className="toggle_button"
      />
      {prefectureCode ? (
        <>
          <label className="job_search_title" htmlFor="job_search_title_01">
            <img
              src={process.env.REACT_APP_SITE_PATH + 'images/area_icon.svg'}
              alt="スポットのアイコン"
              className="job_search_title_icon"
              width="18"
              height="18"
            />
            {getOrderTypeString(currentSearchConditionStrings)
              ? `${getOrderTypeString(
                  currentSearchConditionStrings,
                )}の求人を市区町村別で探す`
              : `求人を市区町村別で探す`}
          </label>
          <div className="job_search_list--wrap">
            {prefectureCode === '13' ? (
              <>
                <input
                  id="job_search_areaname_01"
                  type="checkbox"
                  className="toggle_areabutton"
                />
                <label
                  className="job_search_areaname"
                  htmlFor="job_search_areaname_01"
                >
                  23区
                </label>
                <ul className="job_search_list--areagroup">
                  {municipalities.slice(0, 23).map((municipality) => (
                    <li key={municipality.id} className="job_search_list">
                      <a
                        href={getParamsLink(
                          `municipalityCode=${municipality.id}`,
                          SEARCH_PARAMS_ORDER.municipalityCode,
                          !!municipalityCode,
                        )}
                        className="text_link"
                      >
                        {municipality.name}
                      </a>
                    </li>
                  ))}
                </ul>
                <input
                  id="job_search_areaname_02"
                  type="checkbox"
                  className="toggle_areabutton"
                />
                <label
                  className="job_search_areaname"
                  htmlFor="job_search_areaname_02"
                >
                  23区以外
                </label>
                <ul className="job_search_list--areagroup">
                  {municipalities.slice(23).map((municipality) => (
                    <li key={municipality.id} className="job_search_list">
                      <Link
                        to={getParamsLink(
                          `municipalityCode=${municipality.id}`,
                          SEARCH_PARAMS_ORDER.municipalityCode,
                          !!municipalityCode,
                        )}
                        className="text_link"
                      >
                        {municipality.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <ul className="job_search_list--group">
                  {municipalities.map((municipality) => (
                    <li key={municipality.id} className="job_search_list">
                      <Link
                        to={getParamsLink(
                          `municipalityCode=${municipality.id}`,
                          SEARCH_PARAMS_ORDER.municipalityCode,
                          !!municipalityCode,
                        )}
                        className="text_link"
                      >
                        {municipality.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <label className="job_search_title" htmlFor="job_search_title_01">
            <img
              src={process.env.REACT_APP_SITE_PATH + 'images/area_icon.svg'}
              alt="スポットのアイコン"
              className="job_search_title_icon"
            />
            {currentSearchConditionStrings
              .filter((x, i) =>
                [
                  SEARCH_PARAMS_ORDER.orderType - 1,
                  SEARCH_PARAMS_ORDER.certification - 1,
                ].includes(i),
              )
              .reduce(
                (string, item) => (item ? string + item + 'の' : string),
                '',
              )}
            エリアから探す
          </label>
          <div className="job_search_list--wrap">
            <div className="job_search_area--wrap">
              {REGIONS.map((region, i) => (
                <div key={region} className="job_search--box">
                  <input
                    id={`job_search_areaname_0${i + 1}`}
                    type="checkbox"
                    className="toggle_areabutton"
                  />
                  <label
                    className="job_search_areaname"
                    htmlFor={`job_search_areaname_0${i + 1}`}
                  >
                    {region}
                  </label>
                  <ul className="job_search_list--areagroup">
                    {REGION_MAP[region].map((prefectureCode) => (
                      <li key={prefectureCode} className="job_search_list">
                        <Link
                          to={getParamsLink(
                            `prefectureCode=${prefectureCode}`,
                            SEARCH_PARAMS_ORDER.prefectureCode,
                          )}
                          className="text_link"
                        >
                          {PREFECTURE_OBJECT[prefectureCode]}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MunicipalityLinks;
