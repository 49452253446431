import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { GetOrderIndexesWithCountsQueryQuery } from '../../../types/api';
import { Municipality } from '../../../types/misc';
import FaqText from '../common/faqText';

import RelationalSearchParamsLinks from './relationalSearchParamsLinks';
import SearchResultCard from './result/searchResultCard';
import SearchResultHelmet from './result/searchResultHelmet';

type Props = {
  data: GetOrderIndexesWithCountsQueryQuery | undefined;
  currentPage: number;
  shiftPage: (page: number) => void;
  municipalities: Municipality[];
};

const generateSeq = (currentPage: number, maxPage: number, limit: number) => {
  const seq = [];
  for (let i = currentPage - limit; i <= maxPage; i++) {
    if (i >= 1 && i <= maxPage) {
      seq.push(i);
    }
    if (seq.length === limit * 2 + 1) {
      break;
    }
  }

  let page = currentPage - limit - 1;
  while (seq.length < limit * 2 + 1) {
    if (page <= 0) {
      break;
    }
    seq.unshift(page);
    page -= 1;
  }
  return seq;
};

const SearchResult = ({
  data,
  currentPage,
  shiftPage,
  municipalities,
}: Props) => {
  if (data === undefined || data.getOrderIndexesWithCounts === undefined) {
    return <div>data undefined</div>;
  }
  const count = data.getOrderIndexesWithCounts.count;
  const maxPage = Math.ceil(count / 10);
  useEffect(() => {
    document.body.classList.remove('search');
  }, []);
  return (
    <HelmetProvider>
      <SearchResultHelmet
        hasOrderResult={!!data.getOrderIndexesWithCounts.order_indexes.length}
        municipalities={municipalities}
      />
      <section className="job_list--wrap">
        {data.getOrderIndexesWithCounts.order_indexes.map((order) => (
          <SearchResultCard order={order} key={order.id} />
        ))}
      </section>
      {!!count && (
        <div className="pagination--wrap">
          <ul className="pagination--group">
            <li className="pagination_item_prev">
              <a
                className="pagination_link_prev"
                style={{
                  pointerEvents: currentPage - 1 <= 0 ? 'none' : 'auto',
                }}
                onClick={() => shiftPage(currentPage - 1)}
              >
                ＜
              </a>
            </li>
            {generateSeq(currentPage, maxPage, 2).map((num) => {
              return (
                <li className="pagination_item" key={num}>
                  <a
                    className={`pagination_link ${
                      currentPage === num ? 'pagination_current' : ''
                    }`}
                    onClick={() => shiftPage(num)}
                  >
                    {num}
                  </a>
                </li>
              );
            })}
            <li className="pagination_item_next">
              <a
                className="pagination_link_next"
                style={{
                  pointerEvents: currentPage + 1 > maxPage ? 'none' : 'auto',
                }}
                onClick={() => shiftPage(currentPage + 1)}
              >
                ＞
              </a>
            </li>
          </ul>
        </div>
      )}
      <p className="register_link_button">
        <a href="/register/" className="register_link">
          無料相談はこちらから ▶
        </a>
      </p>
      <div id="action_button" className="fotter_fix_button--wrap">
        <ul className="fotter_fix_button--group">
          <li className="fotter_fix_register_button fotter_fix_button_menu">
            <a href="/register/" className="fotter_fix_button_link">
              無料相談・お仕事紹介は<span className="text_inline">こちら</span>
            </a>
          </li>
          <li className="fotter_fix_phone_button fotter_fix_button_menu">
            <a href="tel:05017409296" className="fotter_fix_button_link">
              <img
                src="/images/phone_icon.svg"
                className="fotter_fix_button_icon"
                alt="電話のアイコン"
              />
            </a>
          </li>
        </ul>
      </div>
      <RelationalSearchParamsLinks searchParams={location.search} />
      <FaqText />
    </HelmetProvider>
  );
};

export default SearchResult;
