import React, { useEffect } from 'react';

import {
  QueryGetOrderIndexesWithCountsArgs,
  useGetOrderIndexesWithCountsQueryLazyQuery,
} from '../../../types/api';
import { EMPLOYMENT_TYPES, ORDER_TYPES } from '../../utils/constants/order';

interface Props {
  orderId: string;
  headerText: string;
  prefectureCode: string;
  municipalityCode: string | null;
  orderType: number;
}

const RelationalRecruitList = (props: Props) => {
  const { orderId, headerText, prefectureCode, municipalityCode, orderType } =
    props;

  const [fetchOrderIndexes, { loading, previousData, data }] =
    useGetOrderIndexesWithCountsQueryLazyQuery();

  const searchIndexes = async (
    prefectureCode: string | null,
    municipalityCode: string | null,
    orderType: string | null,
    page: number,
  ) => {
    const variables: QueryGetOrderIndexesWithCountsArgs = {
      prefecture_code: prefectureCode || undefined,
      municipality_code: municipalityCode || undefined,
      order_type:
        orderType !== '' && orderType !== null
          ? parseInt(orderType, 10)
          : undefined,
      page,
    };
    await fetchOrderIndexes({
      variables,
    });
  };

  useEffect(() => {
    searchIndexes(prefectureCode, municipalityCode, String(orderType), 0);
  }, []);

  const useData = loading ? previousData : data;

  return useData && useData.getOrderIndexesWithCounts.count > 0 ? (
    <section className="near_job--wrap">
      <h2 className="near_job_headline">{headerText}</h2>
      {useData.getOrderIndexesWithCounts.order_indexes.map((orderIndex, i) => {
        if (i < 5 && orderIndex.id != orderId) {
          return (
            <a
              className="near_job_link"
              href={`/detail/${orderIndex.id}`}
              key={`order-detail-${orderIndex.id}`}
            >
              <div className="near_job--box">
                <h3 className="near_job_title">
                  <span className="job_category">
                    {ORDER_TYPES[orderIndex.order_type]}
                  </span>
                  <span className="job_status">
                    {EMPLOYMENT_TYPES[orderIndex.employment_status]}
                  </span>
                  <span className="job_title">
                    {orderIndex.recruitment_headline}
                  </span>
                </h3>
                <dl className="near_job--group">
                  <dt className="job_list_title">
                    <img
                      className="job_list_icon"
                      src="/images/pay_icon.svg"
                      alt="お財布のアイコン"
                      width="18"
                      height="18"
                    />
                    時給
                  </dt>
                  <dd className="job_list_text">
                    {Number(orderIndex.min_hourly_salary).toLocaleString()}円～
                  </dd>
                  <dt className="job_list_title">
                    <img
                      className="job_list_icon"
                      src="/images/area_icon.svg"
                      alt="スポットのアイコン"
                      width="18"
                      height="18"
                    />
                    勤務地
                  </dt>
                  <dd className="job_list_text">{orderIndex.location}</dd>
                </dl>
              </div>
            </a>
          );
        }
      })}
    </section>
  ) : (
    <></>
  );
};

export default RelationalRecruitList;
