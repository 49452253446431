import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useParams, useLocation } from 'react-router-dom';
import '../../css/base.css';
import '../../css/common.css';

import { useFadeAnimation } from '../../hooks/useFadeAnimation';
import { useGetOrderIndexDetailByIdQuery } from '../../types/api';
import NewlineText from '../components/common/newlineText';
import ApplicationButton from '../components/detail/applicationButton';
import DetailBreadList from '../components/detail/detailBreadList';
import DetailHelmet from '../components/detail/detailHelmet';
import RelationalRecruitList from '../components/detail/relationalRecruitList';
import RelationalSearchParamsLinks from '../components/search/relationalSearchParamsLinks';
import {
  CERTIFICATION_NAME,
  EMPLOYMENT_TYPES,
  EMPLOYMENT_TYPES_NUMBER,
  ORDER_TYPES,
} from '../utils/constants/order';
import { scrollToTop } from '../utils/scrollToTop';

interface State {
  queryParams: string;
}

const Detail = () => {
  const { id } = useParams();
  const location = useLocation();
  const { data, loading, error } = useGetOrderIndexDetailByIdQuery({
    variables: {
      id,
    },
  });
  const pageTopButtonRef = useFadeAnimation();

  if (loading) {
    return <div></div>;
  } else if (error) {
    return <div></div>;
  } else if (data === undefined) {
    return <div></div>;
  }

  const {
    order_id,
    office_email,
    certification_helper_novice,
    certification_helper_expert,
    certification_care_worker,
    certification_care_manager,
    certification_nurse,
    certification_associate_nurse,
    certification_child_minder,
    certification_kindergarten_teacher,
    certification_other,
    certification_none_inexperienced,
    certification_none_experienced,
    recruitment_headline,
    appeal_points,
    required_skills,
    other_requirements,
    min_hourly_salary,
    min_daily_salary,
    min_monthly_salary,
    min_annual_salary,
    salary_free_description,
    working_hours,
    holiday,
    working_hours_free_description,
    location: orderLocation,
    access,
    travel_expenses_free_description,
    task,
    employment_status,
    treatment_for_dispatch,
    treatment_for_direct_employment,
    prefecture_code: prefectureCode,
    address_region: prefecture,
    address_locality: municipality,
    municipality_code: municipalityCode,
    order_type: orderType,
  } = { ...data.getOrderIndexById.order_index };

  const certifications = [
    certification_helper_novice,
    certification_helper_expert,
    certification_care_worker,
    certification_care_manager,
    certification_nurse,
    certification_associate_nurse,
    certification_child_minder,
    certification_kindergarten_teacher,
    certification_other,
    certification_none_inexperienced,
    certification_none_experienced,
  ];
  const locationState = location?.state as State | null;
  const queryParams = locationState ? locationState.queryParams : '';
  const url = window.location.href;
  return (
    <HelmetProvider>
      <DetailHelmet orderId={order_id} data={data} />
      <main className="main">
        <DetailBreadList
          prefectureCode={prefectureCode}
          prefecture={prefecture ?? null}
          municipalityCode={municipalityCode}
          municipality={municipality ?? null}
          orderType={orderType}
          queryParams={queryParams}
          orderId={order_id}
        />
        <section className="recruit--wrap">
          <div className="recruit_headline--wrap">
            <h1 className="recruit_page_headline">{recruitment_headline}</h1>
          </div>
          <div className="recruit_contents--inner">
            <table className="recruit--box">
              <tbody>
                <tr>
                  <th className="recruit_list_title">必要な資格</th>
                  <td className="recruit_list_text">
                    {CERTIFICATION_NAME.filter((name, index) => {
                      return certifications[index] ?? name;
                    }).join('、')}
                  </td>
                </tr>
                <tr>
                  <th className="recruit_list_title">雇用形態</th>
                  <td className="recruit_list_text">
                    {EMPLOYMENT_TYPES[employment_status]}
                  </td>
                </tr>
                <tr>
                  <th className="recruit_list_title">
                    必要なスキル
                    <br />
                    (経験)
                  </th>
                  <td className="recruit_list_text">
                    <NewlineText text={required_skills} />
                  </td>
                </tr>
                <tr>
                  <th className="recruit_list_title">
                    その他
                    <br />
                    募集要項
                  </th>
                  <td className="recruit_list_text">
                    <NewlineText text={other_requirements} />
                  </td>
                </tr>
                {employment_status !==
                  EMPLOYMENT_TYPES_NUMBER.INTRODUCE_PROPER && (
                  <tr>
                    <th className="recruit_list_title">時給</th>
                    <td className="recruit_list_text">
                      {Number(min_hourly_salary).toLocaleString()}円～
                    </td>
                  </tr>
                )}
                {working_hours.includes('夜勤') && (
                  <tr>
                    <th className="recruit_list_title">日給</th>
                    <td className="recruit_list_text">
                      {Number(min_daily_salary).toLocaleString()}円～
                    </td>
                  </tr>
                )}
                {employment_status ===
                  EMPLOYMENT_TYPES_NUMBER.INTRODUCE_PROPER && (
                  <tr>
                    <th className="recruit_list_title">月給</th>
                    <td className="recruit_list_text">
                      {Number(min_monthly_salary).toLocaleString()}円～
                    </td>
                  </tr>
                )}
                {employment_status ===
                  EMPLOYMENT_TYPES_NUMBER.INTRODUCE_PROPER && (
                  <tr>
                    <th className="recruit_list_title">年収</th>
                    <td className="recruit_list_text">
                      {Number(min_annual_salary).toLocaleString()}円～
                    </td>
                  </tr>
                )}
                <tr>
                  <th className="recruit_list_title">給与(その他)</th>
                  <td className="recruit_list_text">
                    <NewlineText text={salary_free_description} />
                  </td>
                </tr>
                <tr>
                  <th className="recruit_list_title">勤務時間</th>
                  <td className="recruit_list_text">
                    <NewlineText text={working_hours.replace(/\\n/g, '\n')} />
                  </td>
                </tr>
                <tr>
                  <th className="recruit_list_title">休日</th>
                  <td className="recruit_list_text">
                    <NewlineText text={holiday} />
                  </td>
                </tr>
                <tr>
                  <th className="recruit_list_title">
                    勤務時間
                    <br />
                    (その他)
                  </th>
                  <td className="recruit_list_text">
                    <NewlineText text={working_hours_free_description} />
                  </td>
                </tr>
                <tr>
                  <th className="recruit_list_title">勤務地</th>
                  <td className="recruit_list_text">
                    <NewlineText text={orderLocation} />
                  </td>
                </tr>
                <tr>
                  <th className="recruit_list_title">アクセス</th>
                  <td className="recruit_list_text">
                    <NewlineText text={access} />
                  </td>
                </tr>
                <tr>
                  <th className="recruit_list_title">交通費</th>
                  <td className="recruit_list_text">
                    <NewlineText text={travel_expenses_free_description} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {!!office_email && (
            <ApplicationButton
              orderId={order_id}
              officeEmail={office_email}
              title={recruitment_headline}
              url={url}
            />
          )}
          <section className="recruit_more_contents--inner">
            <div className="recruit_more_contents--inner">
              <h2 className="recruit_more_contents_headline">派遣求人の詳細</h2>
              <section className="recruit_more_contents--box">
                <h3 className="recruit_more_contents_title">業務内容</h3>
                <div className="recruit_text--box">
                  <p className="recruit_text">
                    <NewlineText text={task} />
                  </p>
                </div>
                <h3 className="recruit_more_contents_title">待遇・福利厚生</h3>
                {[
                  EMPLOYMENT_TYPES_NUMBER.INTRODUCE_PROPER,
                  EMPLOYMENT_TYPES_NUMBER.INTRODUCE_PART_TIME,
                ].includes(employment_status) ? (
                  <>
                    <h4 className="recruit_more_contents_sub_title">
                      直接雇用時の待遇
                    </h4>
                    <div className="recruit_text--box">
                      <p className="recruit_text">
                        <NewlineText text={treatment_for_direct_employment} />
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <h4 className="recruit_more_contents_sub_title">
                      派遣時の待遇
                    </h4>
                    <div className="recruit_text--box">
                      <p className="recruit_text">
                        <NewlineText text={treatment_for_dispatch} />
                      </p>
                    </div>
                  </>
                )}
                <h3 className="recruit_more_contents_title">
                  おすすめポイント
                </h3>
                <div className="recruit_text--box">
                  <p className="recruit_text">
                    <NewlineText text={appeal_points} />
                  </p>
                </div>
              </section>
              {!!office_email && (
                <ApplicationButton
                  orderId={order_id}
                  officeEmail={office_email}
                  title={recruitment_headline}
                  url={url}
                />
              )}
              <p className="register_link_button">
                <a href="/register/" className="register_link">
                  無料相談はこちらから ▶
                </a>
              </p>
            </div>
          </section>
        </section>
        <RelationalRecruitList
          orderId={String(id)}
          headerText={`${municipality}付近の${ORDER_TYPES[orderType]}に関連する求人`}
          prefectureCode={prefectureCode}
          municipalityCode={municipalityCode}
          orderType={orderType}
        />
        <RelationalRecruitList
          orderId={String(id)}
          headerText={`この求人と似ている求人`}
          prefectureCode={prefectureCode}
          municipalityCode={null}
          orderType={orderType}
        />
        <RelationalSearchParamsLinks searchParams={queryParams} />
      </main>
      <p
        id="page_top"
        className="page_top"
        ref={pageTopButtonRef}
        onClick={() => scrollToTop('smooth')}
      >
        <a className="pagetop_link"></a>
      </p>
    </HelmetProvider>
  );
};

export default Detail;
