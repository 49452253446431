import React from 'react';

import { useGetOrderIndexesWithCountsQueryQuery } from '../../../types/api';
import { EMPLOYMENT_TYPES, ORDER_TYPES } from '../../utils/constants/order';

const NewOrders = (): React.ReactElement => {
  const { data, loading, error } = useGetOrderIndexesWithCountsQueryQuery({
    variables: {
      limit: 5,
    },
  });

  if (loading) {
    return <div></div>;
  } else if (error) {
    return <div></div>;
  } else if (data === undefined) {
    return <div></div>;
  }

  return (
    <>
      {data.getOrderIndexesWithCounts.order_indexes.map((order) => (
        <a
          key={order.id}
          className="new_job_link"
          href={process.env.REACT_APP_SITE_PATH + 'detail/' + order.id}
        >
          <div className="new_job--box">
            <h3 className="new_job_title">
              <span className="job_category">
                {ORDER_TYPES[order.order_type]}
              </span>
              <span className="job_status">
                {EMPLOYMENT_TYPES[order.employment_status]}
              </span>
              <span className="job_title">{order.recruitment_headline}</span>
            </h3>
            <dl className="new_job--group">
              <dt className="job_list_title">
                <img
                  className="job_list_icon"
                  src={process.env.REACT_APP_SITE_PATH + 'images/pay_icon.svg'}
                  alt="お財布のアイコン"
                  height={18}
                  width={18}
                />
                時給
              </dt>
              <dd className="job_list_text">
                {Number(order.min_hourly_salary).toLocaleString()}円～
              </dd>
              <dt className="job_list_title">
                <img
                  className="job_list_icon"
                  src={process.env.REACT_APP_SITE_PATH + 'images/area_icon.svg'}
                  alt="スポットのアイコン"
                  height={18}
                  width={18}
                />
                勤務地
              </dt>
              <dd className="job_list_text">{order.location}</dd>
            </dl>
          </div>
        </a>
      ))}
    </>
  );
};

export default NewOrders;
