import React, { useCallback, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Municipality } from '../../../types/misc';
import { BreadListContext } from '../../contexts/BreadListContext';

interface SearchBreadListProps {
  municipalities: Municipality[];
}

const SearchBreadList = (props: SearchBreadListProps): React.ReactElement => {
  const { municipalities } = props;

  const { breadList, updateBreadList } = useContext(BreadListContext);

  const update = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const prefectureCode = queryParams.get('prefectureCode');
    const municipalityCode = queryParams.get('municipalityCode');
    const orderType = queryParams.get('orderType');
    const certification =
      queryParams.get('certification') &&
      `certification_${queryParams.get('certification')}`;
    updateBreadList(
      prefectureCode,
      municipalityCode,
      municipalities,
      orderType,
      certification,
    );
  }, [municipalities]);

  useEffect(() => {
    update();
  }, [location.search]);

  return (
    <ul className="bread_list--group">
      <li className="bread_list">
        <Link to="/search" className="bread_list_link">
          <img
            src={process.env.REACT_APP_SITE_PATH + 'images/home_icon.svg'}
            className="bread_list_home_icon"
            alt="家のアイコン"
            width={14}
            height={14}
          />
        </Link>
      </li>
      <>
        {breadList.length ? (
          breadList.map((item, index) => (
            <li key={index} className="bread_list">
              <Link to={item.url} className="bread_list_link">
                {item.title}
              </Link>
            </li>
          ))
        ) : (
          <li className="bread_list">
            <Link to={'/search?page=1'} className="bread_list_link">
              {'すべて'}
            </Link>
          </li>
        )}
      </>
    </ul>
  );
};

export default SearchBreadList;
