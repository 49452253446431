export interface StringKeyObject {
  [key: string]: string;
}

export const PREFECTURE_OBJECT: StringKeyObject = {
  '01': '北海道',
  '02': '青森県',
  '03': '岩手県',
  '04': '宮城県',
  '05': '秋田県',
  '06': '山形県',
  '07': '福島県',
  '08': '茨城県',
  '09': '栃木県',
  '10': '群馬県',
  '11': '埼玉県',
  '12': '千葉県',
  '13': '東京都',
  '14': '神奈川県',
  '15': '新潟県',
  '16': '富山県',
  '17': '石川県',
  '18': '福井県',
  '19': '山梨県',
  '20': '長野県',
  '21': '岐阜県',
  '22': '静岡県',
  '23': '愛知県',
  '24': '三重県',
  '25': '滋賀県',
  '26': '京都府',
  '27': '大阪府',
  '28': '兵庫県',
  '29': '奈良県',
  '30': '和歌山県',
  '31': '鳥取県',
  '32': '島根県',
  '33': '岡山県',
  '34': '広島県',
  '35': '山口県',
  '36': '徳島県',
  '37': '香川県',
  '38': '愛媛県',
  '39': '高知県',
  '40': '福岡県',
  '41': '佐賀県',
  '42': '長崎県',
  '43': '熊本県',
  '44': '大分県',
  '45': '宮崎県',
  '46': '鹿児島県',
  '47': '沖縄県',
};

export const PREFECTURE_CODES = new Array(47)
  .fill(0)
  .map((x, i) => ('00' + String(1 + i)).slice(-2));

export const PREFECTURES = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

export const PREFECTURES_AND_CODES = PREFECTURE_CODES.map((code, i) => {
  return { code, prefectureName: PREFECTURES[i] };
});

export const REGIONS = [
  '北海道・東北',
  '関東',
  '中部',
  '近畿',
  '中国',
  '四国',
  '九州',
];

export const REGION_OBJECT: { [key: string]: typeof REGIONS[number] } = {
  '01': '北海道・東北',
  '02': '北海道・東北',
  '03': '北海道・東北',
  '04': '北海道・東北',
  '05': '北海道・東北',
  '06': '北海道・東北',
  '07': '北海道・東北',
  '08': '関東',
  '09': '関東',
  '10': '関東',
  '11': '関東',
  '12': '関東',
  '13': '関東',
  '14': '関東',
  '15': '中部',
  '16': '中部',
  '17': '中部',
  '18': '中部',
  '19': '中部',
  '20': '中部',
  '21': '中部',
  '22': '中部',
  '23': '中部',
  '24': '近畿',
  '25': '近畿',
  '26': '近畿',
  '27': '近畿',
  '28': '近畿',
  '29': '近畿',
  '30': '近畿',
  '31': '中国',
  '32': '中国',
  '33': '中国',
  '34': '中国',
  '35': '中国',
  '36': '四国',
  '37': '四国',
  '38': '四国',
  '39': '四国',
  '40': '九州',
  '41': '九州',
  '42': '九州',
  '43': '九州',
  '44': '九州',
  '45': '九州',
  '46': '九州',
  '47': '九州',
};

export const REGION_MAP: { [key: typeof REGIONS[number]]: string[] } = {
  '北海道・東北': ['01', '02', '03', '04', '05', '06', '07'],
  関東: ['08', '09', '10', '11', '12', '13', '14'],
  中部: ['15', '16', '17', '18', '19', '20', '21', '22', '23'],
  近畿: ['24', '25', '26', '27', '28', '29', '30'],
  中国: ['31', '32', '33', '34', '35'],
  四国: ['36', '37', '38', '39'],
  九州: ['40', '41', '42', '43', '44', '45', '46', '47'],
};
