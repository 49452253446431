import { useEffect, useRef, useState } from 'react';

const fadeIn = (target: HTMLElement) => {
  target.animate(
    [
      { opacity: 0 },
      {
        opacity: 1,
        display: 'block',
      },
    ],
    {
      duration: 500,
      fill: 'forwards',
    },
  );
};

const fadeOut = (target: HTMLElement) => {
  target.animate(
    [
      { opacity: 1 },
      {
        opacity: 0,
        display: 'none',
      },
    ],
    {
      duration: 500,
      fill: 'forwards',
    },
  );
};

const boundary = 400;

export const useFadeAnimation = () => {
  const [isPageTopButtonVisible, setIsPageTopButtonVisible] =
    useState<boolean>(false);
  const isPageTopButtonVisibleRef = useRef(false);
  isPageTopButtonVisibleRef.current = isPageTopButtonVisible;
  const pageTopButtonRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    const fadeAnimation = () => {
      if (Math.round(window.scrollY) >= boundary && !isPageTopButtonVisible) {
        if (pageTopButtonRef.current) {
          fadeIn(pageTopButtonRef.current);
        }
        setIsPageTopButtonVisible(true);
      }

      if (Math.round(window.scrollY) < boundary && isPageTopButtonVisible) {
        if (pageTopButtonRef.current) {
          fadeOut(pageTopButtonRef.current);
        }
        setIsPageTopButtonVisible(false);
      }
    };
    window.addEventListener('scroll', fadeAnimation);
    return () => {
      window.removeEventListener('scroll', fadeAnimation);
    };
  }, [isPageTopButtonVisible]);

  return pageTopButtonRef;
};
