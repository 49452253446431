import React from 'react';

const FaqText = () => {
  return (
    <section className="faq--wrap" id="faq">
      <h2 className="faq_headline">よくあるご質問</h2>
      <div className="faq--inner">
        <input id="faq_question_01" type="checkbox" className="toggle_button" />
        <label className="faq_question" htmlFor="faq_question_01">
          無料で転職サポートが受けられますか？
        </label>
        <div className="faq_answer--wrap">
          <p className="faq_answer">
            お仕事探しや登録等には費用は一切かかりません。弊社の人材派遣･人材紹介サービスは厚生労働省の許可を受け、介護・医療・一般企業等の求人クライアントとお仕事を探している方の仲介をしております。
          </p>
        </div>
        <input id="faq_question_02" type="checkbox" className="toggle_button" />
        <label className="faq_question" htmlFor="faq_question_02">
          無資格でも登録できますか？
        </label>
        <div className="faq_answer--wrap">
          <p className="faq_answer">
            登録できます。無資格の方や未経験の方もたくさんご活躍されています。また、資格取得支援制度もございます。
          </p>
        </div>
        <input id="faq_question_03" type="checkbox" className="toggle_button" />
        <label className="faq_question" htmlFor="faq_question_03">
          交通費は支給されますか？
        </label>
        <div className="faq_answer--wrap">
          <p className="faq_answer">はい、支給はございます。</p>
        </div>
        <input id="faq_question_04" type="checkbox" className="toggle_button" />
        <label className="faq_question" htmlFor="faq_question_04">
          今すぐの転職は考えていないのですが、登録できますか？
        </label>
        <div className="faq_answer--wrap">
          <p className="faq_answer">
            登録可能です。条件や時期などのご相談だけでもお受けしております！
          </p>
        </div>
        <input id="faq_question_05" type="checkbox" className="toggle_button" />
        <label className="faq_question" htmlFor="faq_question_05">
          年齢やブランクが心配…
        </label>
        <div className="faq_answer--wrap">
          <p className="faq_answer">
            弊社は年齢制限を設けておらず、ブランクがある方もご案内しておりますので、お気軽にご登録ください！
          </p>
        </div>
        <input id="faq_question_06" type="checkbox" className="toggle_button" />
        <label className="faq_question" htmlFor="faq_question_06">
          Wワークは可能ですか？
        </label>
        <div className="faq_answer--wrap">
          <p className="faq_answer">
            可能です。週１～２回などのスキマ時間を活用したい方にも最適です。
          </p>
        </div>
        <input id="faq_question_07" type="checkbox" className="toggle_button" />
        <label className="faq_question" htmlFor="faq_question_07">
          社会保険に加入できますか？
        </label>
        <div className="faq_answer--wrap">
          <p className="faq_answer">
            お仕事の就業条件が社会保険の加入資格を満たす場合、加入手続きを実施しております。
          </p>
        </div>
        <input id="faq_question_08" type="checkbox" className="toggle_button" />
        <label className="faq_question" htmlFor="faq_question_08">
          日払い・週払い可能ですか？
        </label>
        <div className="faq_answer--wrap">
          <p className="faq_answer">可能です。登録時にご希望をお伝え下さい。</p>
        </div>
      </div>
    </section>
  );
};

export default FaqText;
