import React from 'react';
import { Helmet } from 'react-helmet-async';

const SearchHelmet = (): React.ReactElement => {
  return (
    <Helmet>
      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MSW6TRN');`}</script>
      <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
      <meta property="og:url" content="https://haken.careersmile.jp/search/" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="求人をさがす| キャリアスマイル派遣 | 介護士 看護師 保育士のための求人サイト"
      />
      <meta
        property="og:description"
        content="介護士・看護師の有資格者、無資格者、未経験者向けの全国の優良求人多数掲載中！看護助手、初任者研修、実務者研修、介護福祉士、准看護師、正看護師など資格や、勤務地からの検索も可能。毎日更新中！"
      />
      <meta
        property="og:image"
        content="https://haken.careersmile.jp/siteimage.png"
      />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <meta name="keywords" content="キャリア,介護,看護,保育,派遣" />
      <meta name="description" content="" />
      <title>
        求人をさがす| キャリアスマイル派遣 | 介護士 看護師
        保育士のための求人サイト
      </title>
      <link rel="icon" href={process.env.PUBLIC_URL + '/favicon.ico'} />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <link
        href={process.env.PUBLIC_URL + '/css/search.css'}
        rel="stylesheet"
        type="text/css"
      />
      {/* <!-- TETORI tag haken.careersmile.jp --> */}
      <script>
        {`(function (w, d, s, u) {
        // TAG VERSION 1.00
        if (w._wsq_init_flg) {
          return false;
        }
        w._wsq_init_flg = true;
        _wsq = w._wsq || (_wsq = []);
        _wsq.push(['init', u, 1827]);
        _wsq.push(['domain', 'haken.careersmile.jp']);
        var a = d.createElement(s); a.async = 1; a.charset='UTF-8'; a.src = 'https://cdn.' + u + '/share/js/tracking.js';
        var b = d.getElementsByTagName(s)[0]; b.parentNode.insertBefore(a, b);
      })(window, document, 'script', 'tetori.link');`}
      </script>
    </Helmet>
  );
};

export default SearchHelmet;
