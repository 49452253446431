import { BreadList } from './breadListType';

export const createJsonLdStructuredData = (breadList: BreadList[]) => {
  const itemList = breadList.map((breadObject, i) => ({
    '@type': 'ListItem',
    position: i + 1,
    name: breadObject.title,
    item: process.env.PUBLIC_URL + breadObject.url,
  }));
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemList,
  };
};
