import React from 'react';

const ApplicationFooter = (): React.ReactElement => {
  return (
    <footer className="footer">
      <nav className="footer_menu">
        <ul className="footer_menu_sp">
          <li className="footer_menu_list_sp">
            <a
              href={process.env.REACT_APP_SITE_PATH + '#about'}
              className="footer_menu_link_sp"
            >
              キャリアスマイル派遣とは
            </a>
          </li>
          <li className="footer_menu_list_sp">
            <a
              href={process.env.REACT_APP_SITE_PATH + '#support'}
              className="footer_menu_link_sp"
            >
              安心サポート
            </a>
          </li>
          <li className="footer_menu_list_sp">
            <a
              href={process.env.REACT_APP_SITE_PATH + 'welfare/'}
              className="footer_menu_link_sp"
            >
              福利厚生
            </a>
          </li>
          <li className="footer_menu_list_sp">
            <a
              href={process.env.REACT_APP_SITE_PATH + '#workstyle'}
              className="footer_menu_link_sp"
            >
              選べる働きかた
            </a>
          </li>
          <li className="footer_menu_list_sp">
            <a
              href={process.env.REACT_APP_SITE_PATH + '#flow'}
              className="footer_menu_link_sp"
            >
              就業までのながれ
            </a>
          </li>
          <li className="footer_menu_list_sp">
            <a
              href={process.env.REACT_APP_SITE_PATH + '#faq'}
              className="footer_menu_link_sp"
            >
              よくあるご質問
            </a>
          </li>
          <li className="footer_menu_list_sp">
            <a
              href={process.env.REACT_APP_SITE_PATH + 'friends/'}
              className="footer_menu_link_sp"
            >
              お友だち紹介
            </a>
          </li>
          <li className="footer_menu_list_sp">
            <a
              href={process.env.REACT_APP_SITE_PATH + 'system/'}
              className="footer_menu_link_sp"
            >
              資格取得支援制度
            </a>
          </li>
          <li className="footer_menu_list_sp">
            <a
              href={process.env.REACT_APP_SITE_PATH + 'company/'}
              className="footer_menu_link_sp"
            >
              人材をお探しの採用担当者様はこちら
            </a>
          </li>
        </ul>
        <ul className="footer_sub_menu">
          <li className="footer_sub_menu_list">
            <a
              href="/profile"
              target="_blank"
              rel="noopener noreferrer"
              className="footer_sub_menu_link"
            >
              運営会社
            </a>
          </li>
          <li className="footer_sub_menu_list">
            <a
              href="/policy"
              target="_blank"
              rel="noopener noreferrer"
              className="footer_sub_menu_link"
            >
              個人情報保護方針
            </a>
          </li>
          <li className="footer_sub_menu_list">
            <a
              href="/terms"
              target="_blank"
              rel="noopener noreferrer"
              className="footer_sub_menu_link"
            >
              利用規約
            </a>
          </li>
          <li className="footer_sub_menu_list">
            <a href="/sitemap/" className="footer_sub_menu_link">
              サイトマップ
            </a>
          </li>
        </ul>
      </nav>
      <div className="footer_ninteimark--group">
        <a
          className="footer_nintei_link"
          href="https://www.jesra.or.jp/tekiseinintei/certifications/1852/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="footer_mark--box">
            <p className="footer_ninteimark">
              <img
                src={process.env.REACT_APP_SITE_PATH + 'images/iryou_mark.png'}
                alt="医療分野における適正な有料職業紹介事業者認定マーク"
                width="50"
                height="50"
              />
            </p>
            <p>
              医療分野における適正な
              <span className="footer_ninteimark_text">
                有料職業紹介事業者認定
              </span>
            </p>
          </div>
        </a>
        <div className="footer_mark--box">
          <p className="footer_ninteimark">
            <img
              src={process.env.REACT_APP_SITE_PATH + 'images/kaigo_mark.png'}
              alt="介護分野における適正な有料職業紹介事業者認定マーク"
              width="50"
              height="50"
            />
          </p>
          <p>
            介護分野における適正な
            <span className="footer_ninteimark_text">
              有料職業紹介事業者認定
            </span>
          </p>
        </div>
      </div>
      <div className="footer_privacymark--wrap">
        <p className="footer_privacymark">
          <a
            href="https://privacymark.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={process.env.REACT_APP_SITE_PATH + 'images/privacy.png'}
              alt="プライバシーマーク"
              width="50"
              height="52"
            />
          </a>
        </p>
        <p className="footer_privacymark_text">
          運営元の株式会社キャリアは「プライバシーマーク」認定事業者です。ご利用者様の個人情報を保護するためSSL(Secure
          Sockets Layer)暗号化技術を用いております。
        </p>
      </div>
      <div className="detail_footer_copy--wrap">
        <div className="footer_copy--inner">
          <p className="footer_logo">
            <img
              src={process.env.REACT_APP_SITE_PATH + 'images/footer_logo.png'}
              alt="株式会社キャリアロゴ"
              width="60"
              height="60"
            />
          </p>
          <p className="footer_copy">
            <small>Copyright (C) 2021 career All Rights Reserved.</small>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default ApplicationFooter;
