import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { Municipality } from '../../../../types/misc';
import { BreadListContext } from '../../../contexts/BreadListContext';
import {
  getCertification,
  getMunicipality,
  getOrderType,
} from '../../../utils/constants/order';
import { PREFECTURE_OBJECT } from '../../../utils/constants/prefectures';
import { createJsonLdStructuredData } from '../../../utils/createJsonLdStructuredData';

interface SearchResultHelmetProps {
  hasOrderResult: boolean;
  municipalities: Municipality[];
}

const SearchResultHelmet = (
  props: SearchResultHelmetProps,
): React.ReactElement => {
  const { hasOrderResult, municipalities } = props;

  const queryParams = new URLSearchParams(location.search);
  const prefectureCode = queryParams.get('prefectureCode');
  const municipalityCode = queryParams.get('municipalityCode');
  const orderType = queryParams.get('orderType');
  const certification =
    queryParams.get('certification') &&
    `certification_${queryParams.get('certification')}`;

  const prefecture = prefectureCode ? PREFECTURE_OBJECT[prefectureCode] : null;
  const municipalityName =
    getMunicipality(municipalities, municipalityCode) || null;
  const orderTypeName = getOrderType(orderType) || null;
  const certificationName = getCertification(certification) || null;

  const titlePrefix = [
    prefecture,
    municipalityName,
    orderTypeName,
    certificationName,
  ].reduce((acc, cur) => (cur === null ? acc : acc + cur + ' '), '');
  const title = titlePrefix
    ? titlePrefix + 'の派遣求人一覧｜介護士 看護師 保育士のための求人サイト'
    : '派遣求人一覧｜介護士 看護師 保育士のための求人サイト';

  const { breadList } = useContext(BreadListContext);

  return (
    <Helmet>
      <head prefix="og: http://ogp.me/ns#" />

      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MSW6TRN');`}</script>
      <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
      <meta
        property="og:url"
        content="https://haken.careersmile.jp/search/search_result.html"
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content="介護士・看護師の有資格者、無資格者、未経験者向けの全国の優良求人多数掲載中！看護助手、初任者研修、実務者研修、介護福祉士、准看護師、正看護師など資格や、勤務地からの検索も可能。毎日更新中！"
      />
      <meta
        property="og:image"
        content="https://haken.careersmile.jp/siteimage.png"
      />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <meta name="keywords" content="キャリア,介護,看護,保育,派遣" />
      <meta name="description" content="" />
      <title>{title}</title>
      <script type="application/ld+json">
        {JSON.stringify(createJsonLdStructuredData(breadList))}
      </script>
      {hasOrderResult ? (
        <link
          rel="canonical"
          href={process.env.PUBLIC_URL + '/' + location.search}
        />
      ) : (
        <meta name="robots" content="noindex" />
      )}
      <link rel="icon" href={process.env.PUBLIC_URL + '/favicon.ico'} />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <link
        href={process.env.PUBLIC_URL + '/css/search.css'}
        rel="stylesheet"
        type="text/css"
      />
      <noscript>
        {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MSW6TRN"
            height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>`}
      </noscript>
      {/* <!-- TETORI tag haken.careersmile.jp --> */}
      <script>
        {`(function (w, d, s, u) {
        // TAG VERSION 1.00
        if (w._wsq_init_flg) {
          return false;
        }
        w._wsq_init_flg = true;
        _wsq = w._wsq || (_wsq = []);
        _wsq.push(['init', u, 1827]);
        _wsq.push(['domain', 'haken.careersmile.jp']);
        var a = d.createElement(s); a.async = 1; a.charset='UTF-8'; a.src = 'https://cdn.' + u + '/share/js/tracking.js';
        var b = d.getElementsByTagName(s)[0]; b.parentNode.insertBefore(a, b);
      })(window, document, 'script', 'tetori.link');`}
      </script>
    </Helmet>
  );
};

export default SearchResultHelmet;
