import { Municipality } from '../../../types/misc';

export const CERTIFICATIONS = [
  {
    certificationCode: 'certification_none_inexperienced',
    certificationName: '無資格未経験可',
  },
  {
    certificationCode: 'certification_none_experienced',
    certificationName: '無資格経験者可',
  },
  {
    certificationCode: 'certification_helper_novice',
    certificationName: '初任者研修',
  },
  {
    certificationCode: 'certification_helper_expert',
    certificationName: '実務者研修',
  },
  {
    certificationCode: 'certification_care_worker',
    certificationName: '介護福祉士',
  },
  {
    certificationCode: 'certification_care_manager',
    certificationName: 'ケアマネジャー',
  },
  { certificationCode: 'certification_nurse', certificationName: '正看護師' },
  {
    certificationCode: 'certification_associate_nurse',
    certificationName: '准看護師',
  },
  {
    certificationCode: 'certification_child_minder',
    certificationName: '保育士',
  },
  {
    certificationCode: 'certification_kindergarten_teacher',
    certificationName: '幼稚園教諭',
  },
  {
    certificationCode: 'certification_other',
    certificationName: 'その他',
  },
];

export const ORDER_TYPES = ['介護', '看護', '保育', 'その他'];

export const ORDER_TYPES_OBJECT = [
  {
    code: '0',
    name: '介護',
  },
  {
    code: '1',
    name: '看護',
  },
  {
    code: '3',
    name: '保育',
  },
  {
    code: '2',
    name: 'その他',
  },
];

export const EMPLOYMENT_TYPES_NUMBER = {
  /*
  一般派遣
   */
  TEMPORARY: 0,
  /*
  紹介予定派遣
   */
  WILL_INTRODUCE: 1,
  /*
  紹介(パート)
 */
  INTRODUCE_PART_TIME: 2,
  /*
  紹介(正社員)
   */
  INTRODUCE_PROPER: 3,
};
export type EMPLOYMENT_TYPES_NUMBER =
  typeof EMPLOYMENT_TYPES_NUMBER[keyof typeof EMPLOYMENT_TYPES_NUMBER];

export const EMPLOYMENT_TYPES = [
  '一般派遣',
  '紹介予定派遣',
  '紹介(パート)',
  '紹介(正社員)',
];

export const CERTIFICATION_NAME = [
  '初任者研修',
  '実務者研修',
  '介護福祉士',
  'ケアマネジャー',
  '正看護師',
  '准看護師',
  '保育士',
  '幼稚園教諭',
  'その他',
  '無資格未経験可',
  '無資格経験者可',
];

export const getMunicipality = (
  municipalities: Municipality[],
  municipalityCode: string | null,
) => {
  const candidates = municipalities.filter(
    (municipality) => municipality.id === municipalityCode,
  );
  return candidates.length !== 0 ? candidates[0].name : null;
};

export const getOrderType = (orderType: string | null) => {
  const candidates = ORDER_TYPES_OBJECT.filter(
    (item) => item.code === orderType,
  );
  return candidates.length !== 0 ? candidates[0].name : null;
};

export const getCertification = (certification: string | null) => {
  const candidates = CERTIFICATIONS.filter(
    (item) => item.certificationCode === certification,
  );
  return candidates.length !== 0 ? candidates[0].certificationName : null;
};
