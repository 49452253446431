import React from 'react';
import { Link } from 'react-router-dom';

const ApplicationHeader = (): React.ReactElement => {
  return (
    <header className="header">
      <div className="header--inner">
        <p className="header_site_headline">
          <span className="header_site_text">
            介護士 看護師 保育士のための
            <span className="text_inline">求人サイト</span>
          </span>
          <a href={process.env.REACT_APP_SITE_PATH}>
            <img
              src={process.env.REACT_APP_SITE_PATH + 'images/sitelogo.svg'}
              alt="キャリアスマイル派遣"
              width="280"
              className="header_site_logo"
            />
          </a>
        </p>
        <div className="header_phone_pc--wrap">
          <p className="header_phone_note">お電話でのご相談はこちら</p>
          <div className="header_phone--wrap">
            <p>
              <a href="tel:05017409296" className="header_phone_link">
                050-1740-9296
              </a>
            </p>
            <p className="header_phone_time">受付時間：9:30-18:30(平日対応)</p>
          </div>
        </div>

        <input type="checkbox" id="menu_button_check" />
        <label htmlFor="menu_button_check" className="menu_button">
          <span className="menu_line"></span>
          <span className="menu_text">メニュー</span>
        </label>
        <nav className="header_nav_sp">
          {' '}
          <a href={process.env.REACT_APP_SITE_PATH + 'register/'}>
            <div className="header_register_banner--wrap">
              <div className="header_register_banner--inner">
                <p className="header_register_banner_image">
                  <img
                    src={
                      process.env.REACT_APP_SITE_PATH + 'images/smairuca.svg'
                    }
                    alt="スマイルカ"
                    width="200"
                    height="150"
                  />
                </p>
                <div className="header_register_banner_text">
                  <p className="header_register_banner_sub_text">
                    夜勤・残業なし・土日休み・時短・駅近
                    <br />
                    職場円満・短期・長期・高時給
                  </p>
                  <p className="header_register_banner_main_text">
                    今すぐ無料相談 ▶
                  </p>
                  <p className="header_register_banner_bottom_text">
                    お任せください！ご希望の求人をご紹介します！
                  </p>
                </div>
              </div>
            </div>
          </a>
          <p className="header_search_button">
            <Link to="/search" className="header_search_button_link">
              求人をさがす
            </Link>
          </p>
          <input
            id="sp_job_search_title_01"
            type="checkbox"
            className="toggle_button"
          />
          <label className="job_search_title" htmlFor="sp_job_search_title_01">
            <img
              src={process.env.REACT_APP_SITE_PATH + 'images/area_icon.svg'}
              alt="スポットのアイコン"
              className="job_search_title_icon"
              width={18}
              height={18}
            />
            エリアから探す
          </label>
          <div className="job_search_list--wrap">
            <div className="job_search--box">
              <input
                id="sp_job_search_areaname_01"
                type="checkbox"
                className="toggle_areabutton"
              />
              <label
                className="job_search_areaname"
                htmlFor="sp_job_search_areaname_01"
              >
                北海道・東北
              </label>
              <ul className="job_search_list--areagroup">
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=01"
                    className="text_link"
                  >
                    北海道
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=02"
                    className="text_link"
                  >
                    青森
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=03"
                    className="text_link"
                  >
                    岩手
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=04"
                    className="text_link"
                  >
                    宮城
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=05"
                    className="text_link"
                  >
                    秋田
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=06"
                    className="text_link"
                  >
                    山形
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=07"
                    className="text_link"
                  >
                    福島
                  </Link>
                </li>
              </ul>
              <input
                id="sp_job_search_areaname_02"
                type="checkbox"
                className="toggle_areabutton"
              />
              <label
                className="job_search_areaname"
                htmlFor="sp_job_search_areaname_02"
              >
                関東
              </label>
              <ul className="job_search_list--areagroup">
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=08"
                    className="text_link"
                  >
                    茨城
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=09"
                    className="text_link"
                  >
                    栃木
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=10"
                    className="text_link"
                  >
                    群馬
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=11"
                    className="text_link"
                  >
                    埼玉
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=12"
                    className="text_link"
                  >
                    千葉
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=13"
                    className="text_link"
                  >
                    東京
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=14"
                    className="text_link"
                  >
                    神奈川
                  </Link>
                </li>
              </ul>
              <input
                id="sp_job_search_areaname_03"
                type="checkbox"
                className="toggle_areabutton"
              />
              <label
                className="job_search_areaname"
                htmlFor="sp_job_search_areaname_03"
              >
                甲信越・北陸
              </label>
              <ul className="job_search_list--areagroup">
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=15"
                    className="text_link"
                  >
                    新潟
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=16"
                    className="text_link"
                  >
                    富山
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=17"
                    className="text_link"
                  >
                    石川
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=18"
                    className="text_link"
                  >
                    福井
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=19"
                    className="text_link"
                  >
                    山梨
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=20"
                    className="text_link"
                  >
                    長野
                  </Link>
                </li>
              </ul>
              <input
                id="sp_job_search_areaname_04"
                type="checkbox"
                className="toggle_areabutton"
              />
              <label
                className="job_search_areaname"
                htmlFor="sp_job_search_areaname_04"
              >
                東海
              </label>
              <ul className="job_search_list--areagroup">
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=21"
                    className="text_link"
                  >
                    岐阜
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=22"
                    className="text_link"
                  >
                    静岡
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=23"
                    className="text_link"
                  >
                    愛知
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=24"
                    className="text_link"
                  >
                    三重
                  </Link>
                </li>
              </ul>
            </div>
            <div className="job_search--box">
              <input
                id="sp_job_search_areaname_05"
                type="checkbox"
                className="toggle_areabutton"
              />
              <label
                className="job_search_areaname"
                htmlFor="sp_job_search_areaname_05"
              >
                関西
              </label>
              <ul className="job_search_list--areagroup">
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=25"
                    className="text_link"
                  >
                    滋賀
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=26"
                    className="text_link"
                  >
                    京都
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=27"
                    className="text_link"
                  >
                    大阪
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=28"
                    className="text_link"
                  >
                    兵庫
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=29"
                    className="text_link"
                  >
                    奈良
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=30"
                    className="text_link"
                  >
                    和歌山
                  </Link>
                </li>
              </ul>
              <input
                id="sp_job_search_areaname_06"
                type="checkbox"
                className="toggle_areabutton"
              />
              <label
                className="job_search_areaname"
                htmlFor="sp_job_search_areaname_06"
              >
                中国・四国
              </label>
              <ul className="job_search_list--areagroup">
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=31"
                    className="text_link"
                  >
                    鳥取
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=32"
                    className="text_link"
                  >
                    島根
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=33"
                    className="text_link"
                  >
                    岡山
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=34"
                    className="text_link"
                  >
                    広島
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=35"
                    className="text_link"
                  >
                    山口
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=36"
                    className="text_link"
                  >
                    徳島
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=37"
                    className="text_link"
                  >
                    香川
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=38"
                    className="text_link"
                  >
                    愛媛
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=39"
                    className="text_link"
                  >
                    高知
                  </Link>
                </li>
              </ul>
              <input
                id="sp_job_search_areaname_07"
                type="checkbox"
                className="toggle_areabutton"
              />
              <label
                className="job_search_areaname"
                htmlFor="sp_job_search_areaname_07"
              >
                九州・沖縄
              </label>
              <ul className="job_search_list--areagroup">
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=40"
                    className="text_link"
                  >
                    福岡
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=41"
                    className="text_link"
                  >
                    佐賀
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=42"
                    className="text_link"
                  >
                    長崎
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=43"
                    className="text_link"
                  >
                    熊本
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=44"
                    className="text_link"
                  >
                    大分
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=45"
                    className="text_link"
                  >
                    宮崎
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=46"
                    className="text_link"
                  >
                    鹿児島
                  </Link>
                </li>
                <li className="job_search_list">
                  <Link
                    to="/search?page=1&prefectureCode=47"
                    className="text_link"
                  >
                    沖縄
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <input
            id="sp_job_search_title_02"
            type="checkbox"
            className="toggle_button"
          />
          <label className="job_search_title" htmlFor="sp_job_search_title_02">
            <img
              src={process.env.REACT_APP_SITE_PATH + 'images/work_icon.svg'}
              alt="ビルのアイコン"
              className="job_search_title_icon"
            />
            職種から探す
          </label>
          <div className="job_search_list--wrap">
            <ul className="job_search_list--group">
              <li className="job_search_list">
                <a href="/search?page=1&orderType=0" className="text_link">
                  介護
                </a>
              </li>
              <li className="job_search_list">
                <a href="/search?page=1&orderType=1" className="text_link">
                  看護
                </a>
              </li>
              <li className="job_search_list">
                <a href="/search?page=1&orderType=3" className="text_link">
                  保育
                </a>
              </li>
              <li className="job_search_list">
                <a href="/search?page=1&orderType=2" className="text_link">
                  その他
                </a>
              </li>
            </ul>
          </div>
          <ul className="header_menu">
            <li className="header_menu_list">
              <a
                href={process.env.REACT_APP_SITE_PATH + '#about'}
                className="header_menu_link"
              >
                キャリアスマイル派遣とは
              </a>
            </li>
            <li className="header_menu_list">
              <a
                href={process.env.REACT_APP_SITE_PATH + '#support'}
                className="header_menu_link"
              >
                安心サポート
              </a>
            </li>
            <li className="header_menu_list">
              <a
                href={process.env.REACT_APP_SITE_PATH + 'welfare/'}
                className="header_menu_link"
              >
                福利厚生
              </a>
            </li>
            <li className="header_menu_list">
              <a
                href={process.env.REACT_APP_SITE_PATH + '#workstyle'}
                className="header_menu_link"
              >
                選べる働きかた
              </a>
            </li>
            <li className="header_menu_list">
              <a
                href={process.env.REACT_APP_SITE_PATH + '#flow'}
                className="header_menu_link"
              >
                就業までのながれ
              </a>
            </li>
            <li className="header_menu_list">
              <a
                href={process.env.REACT_APP_SITE_PATH + '#faq'}
                className="header_menu_link"
              >
                よくあるご質問
              </a>
            </li>
            <li className="header_menu_list">
              <a
                href={process.env.REACT_APP_SITE_PATH + 'friends/'}
                className="header_menu_link"
              >
                お友だち紹介
              </a>
            </li>
            <li className="header_menu_list">
              <a
                href={process.env.REACT_APP_SITE_PATH + 'system/'}
                className="header_menu_link"
              >
                資格取得支援制度
              </a>
            </li>
            <li className="header_menu_list">
              <a
                href={process.env.REACT_APP_SITE_PATH + 'company/'}
                className="header_menu_link"
              >
                採用担当者様へ
              </a>
            </li>
          </ul>
          <div className="header_phone_sp--wrap">
            <p className="header_phone_note">お電話でのご相談はこちら</p>
            <div className="header_phone--wrap">
              <p>
                <a href="tel:05017409296" className="header_phone_link">
                  050-1740-9296
                </a>
              </p>
              <p className="header_phone_time">
                受付時間：9:30-18:30(平日対応)
              </p>
            </div>
          </div>
          <ul className="header_menu_external_link">
            <li>
              <a
                href="/profile/"
                target="_blank"
                rel="noopener noreferrer"
                className="header_external_link"
              >
                会社概要
              </a>
            </li>
            <li>
              <a
                href="/policy"
                target="_blank"
                rel="noopener noreferrer"
                className="header_external_link"
              >
                個人情報保護方針
              </a>
            </li>
            <li>
              <a href="/terms/" className="header_external_link">
                利用規約
              </a>
            </li>
            <li>
              <a href="/sitemap/" className="header_external_link">
                サイトマップ
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default ApplicationHeader;
